export default function removeNullValuesAndFormat(obj) {
	return Object.entries(obj)
		.filter(([, value]) => {
			if (!value) {
				return false;
			} else {
				return true;
			}
		}) // Filter out entries with null values
		.map(([key, value]) => `${key}=${value}`); // Format as "key=value" strings
}
