import axios from "axios";
import { API_ROUTES } from "constants/api.constants";
import { BASE_API_URL } from "constants/api.constants";
import removeNullValuesAndFormat from "utils/removeNullAndFormat";

export async function getQuotesService(filterObj) {
  try {
    let url = BASE_API_URL + API_ROUTES.GET_QUOTES;
    let params = removeNullValuesAndFormat(filterObj);
    console.log(params);
    if (params.length) {
      url += "?" + params.join("&");
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getInvoicesService(filterObj) {
  try {
    let url = BASE_API_URL + API_ROUTES.GET_INVOICES;
    let params = removeNullValuesAndFormat(filterObj);
    if (params.length) {
      url += "?" + params.join("&");
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getUsagesService(filterObj) {
  try {
    let url = BASE_API_URL + API_ROUTES.GET_USAGES;
    let params = removeNullValuesAndFormat(filterObj);
    if (params.length) {
      url += "?" + params.join("&");
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
