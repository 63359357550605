import { Box, Text, Input, Select, Button,Link,Icon } from "@chakra-ui/react";
import { ReactComponent as DropdownIcon } from '../../assets/icons/dropdown.svg';

const AddNewVPN = () => {
  return (
    <Box
      bg="gray.50"
      borderRadius="md"
      width="100%"
      mx="auto"
    >
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          Account Information
        </Text>

        <Select placeholder="Select Account Number" mb={4} bg="white" icon={<Icon as={DropdownIcon} />}>
          {/* Add options here */}
        </Select>
        <Text textAlign="right" mb={6}>
          Don’t have the customer yet?{" "}
          <Link href="#" textDecoration="underline" _hover={{textDecoration:"underline"}}>
            Create one
          </Link>
        </Text>
        <Input placeholder="E-Mail Address" mb={2} bg="white" />
        <Select placeholder="Select Region" mb={4} bg="white" icon={<Icon as={DropdownIcon} />}>
          {/* Add options here */}
        </Select>
      </Box>

      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          VPN Information
        </Text>

        <Select placeholder="Enabled" mb={4} bg="white" icon={<Icon as={DropdownIcon} />}>
          {/* Add options for enabled/disabled */}
        </Select>

        <Select placeholder="VPN Type" mb={4} bg="white" icon={<Icon as={DropdownIcon} />}>
          {/* Add VPN type options */}
        </Select>
      </Box>

      <Button
        bg="#422AFB"
        _hover={{bg:"#422AFB"}}
        _focus={{bg:"#422AFB"}}
        color="white"
        width="100%"
        py={6}
        borderRadius="xl"
        fontSize="md"
      >
        Confirm
      </Button>
    </Box>
  );
};

export default AddNewVPN;
