import React, { useEffect } from "react";
import {
	Box,
	SimpleGrid,
	Spinner,
	useColorModeValue,
	Center,
	Divider,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { DashBoardTable } from "components/table/Table";
import { useGetBillingUsage } from "features/Billing/useGetBillingUsage";

const heads = [
	"Account Name",
	"Account Number",
	"Product Category",
	"Products",
];

const Usage = () => {
	const currentDate = new Date();
	const oneMonthBefore = new Date(currentDate);
	oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
	oneMonthBefore.setDate(1);
	const omb_date = oneMonthBefore.toISOString().split("T")[0];

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const account_number = queryParams.get("accountNumber");
	const start_date = queryParams.get("startDate");
	const end_date = queryParams.get("endDate");

	// Fetch Data
	const {
		isLoading: fetchingOrders,
		billingUsage,
		results,
		refetch,
	} = useGetBillingUsage({
		account_number,
		start_date: start_date || omb_date,
		end_date: end_date || new Date().toISOString().split("T")[0],
	});

	useEffect(() => {
		refetch();
	}, [location.search, refetch]);

	// Chakra Color Mode
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

	return (
		<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
			{fetchingOrders ? (
				<Center h="25rem">
					<Spinner size="xl" color="blue.500" />
				</Center>
			) : (
				<>
					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="30px" mb="30px">
						<DashBoardTable heads={heads} data={billingUsage} onRowClick={() => {}}/>
					</SimpleGrid>
				</>
			)}
		</Box>
	);
};

export default Usage;
