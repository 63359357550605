import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  Icon,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  FormControl,
  Stack,
  Checkbox,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success.svg';

const BillingInformation = ({
  formData,
  updateFormData,
  isModalOpen,
  setIsModalOpen,
  setCreated,
  orderType,
}) => {
  const handleDashboardRedirect = () => {
    setIsModalOpen(false);
    setCreated();
  };

  return (
    <Box pb={5} width="100%">
      <Box>
        <FormControl>
          <Text fontWeight="bold" mb={4}>
            IP Selection
          </Text>
          <Stack
            direction="row"
            spacing={4}
            width="70%"
            justifyContent="space-between"
          >
            <Checkbox
              isChecked={formData.partnerBilling}
              onChange={() =>
                updateFormData({ partnerBilling: !formData.partnerBilling })
              }
            >
              Partner Billing
            </Checkbox>
            <Checkbox
              isChecked={formData.customerBilling}
              onChange={() =>
                updateFormData({ customerBilling: !formData.customerBilling })
              }
            >
              Customer Billing
            </Checkbox>
          </Stack>
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <Text fontWeight="bold" my={4}>
          Billing Contact Details
        </Text>

        <Stack spacing={4}>
          <Flex gap={4}>
            <Input
              placeholder="First Name"
              bg="#EAEAEA"
              value={formData.firstName || ""}
              onChange={(e) => updateFormData({ firstName: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
            <Input
              placeholder="Last Name"
              bg="#EAEAEA"
              value={formData.lastName || ""}
              onChange={(e) => updateFormData({ lastName: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
          </Flex>

          <Flex gap={4}>
            <Input
              placeholder="Email Address"
              bg="#EAEAEA"
              value={formData.email || ""}
              onChange={(e) => updateFormData({ email: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
            <Input
              placeholder="Job Title"
              bg="#EAEAEA"
              value={formData.jobTitle || ""}
              onChange={(e) => updateFormData({ jobTitle: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
          </Flex>
        </Stack>

        <Text fontWeight="bold" my={4}>
          Billing Address
        </Text>

        <Stack spacing={4}>
          <Input
            placeholder="Address Line 1"
            bg="#EAEAEA"
            value={formData.addressLine1 || ""}
            onChange={(e) => updateFormData({ addressLine1: e.target.value })}
            _placeholder={{ color: "black", fontWeight: "normal" }}
            disabled={true}
          />

          <Flex gap={4}>
            <Input
              placeholder="Address Line 2"
              bg="#EAEAEA"
              value={formData.addressLine2 || ""}
              onChange={(e) => updateFormData({ addressLine2: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
            <Input
              placeholder="City"
              bg="#EAEAEA"
              value={formData.city || ""}
              onChange={(e) => updateFormData({ city: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
          </Flex>

          <Flex gap={4}>
            <Input
              placeholder="State"
              bg="#EAEAEA"
              value={formData.state || ""}
              onChange={(e) => updateFormData({ state: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
            <Input
              placeholder="Country"
              bg="#EAEAEA"
              value={formData.country || ""}
              onChange={(e) => updateFormData({ country: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
            <Input
              placeholder="Zipcode"
              bg="#EAEAEA"
              value={formData.zipcode || ""}
              onChange={(e) => updateFormData({ zipcode: e.target.value })}
              _placeholder={{ color: "black", fontWeight: "normal" }}
              disabled={true}
            />
          </Flex>
        </Stack>
      </Box>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent alignItems="center" py={3} px={1} borderRadius={16}>
          <ModalHeader textAlign="center">
            <Icon as={SuccessIcon} w={20} h={20} color="#20bf55" />
            <Text fontWeight="bold" fontSize="3xl">
              Order Created Successfully
            </Text>
          </ModalHeader>
          <ModalBody textAlign="center">
            <Text>
              The contract has been sent to vivekkumar@gmail.com for signing.
            </Text>
          </ModalBody>
          <ModalFooter w="full">
            <Button
              color="#422AFB"
              bgColor="#EDEBFF"
              w="full"
              borderRadius={10}
              _hover={{ bg: "#EDEBFF" }}
              onClick={handleDashboardRedirect}
            >
              Back to Dashboard
            </Button>
            <Button
              color="white"
              bgColor="#422AFB"
              _hover={{bgColor:"#422AFB"}}
              w="full"
              borderRadius={10}
              ml={2}
              onClick={() => setIsModalOpen(false)} 
            >
              View Contract
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BillingInformation;