import { useForm, Controller } from "react-hook-form";
import {
  Stack,
  FormControl,
  Input,
  Select,
  Checkbox,
  Button,Icon,
  FormErrorMessage,
  Flex,
  Text,
} from "@chakra-ui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/dropdown.svg'

const schema = z.object({
  ip_selection: z
    .object({
      partner_billing: z.boolean(),
      customer_billing: z.boolean(),
    })
    .refine((data) => data.partner_billing || data.customer_billing, {
      message: "At least one billing option must be selected",
    }),
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().email({ message: "Invalid email address" }),
  job_title: z.string().min(1, { message: "Job title is required" }),
  address_line_1: z.string().min(1, { message: "Address Line 1 is required" }),
  address_line_2: z.string().optional(),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  country: z.string().min(1, { message: "Country is required" }),
  zipcode: z.string().min(1, { message: "Zipcode is required" }),
});

const BillingDetailsForm = ({
  setStep,
  formData,
  updateFormData,
  onComplete,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: zodResolver(schema),
    defaultValues: {
      ip_selection: formData.billing_details?.ip_selection || {
        partner_billing: false,
        customer_billing: true,
      },
      first_name: formData.billing_details?.first_name || "",
      last_name: formData.billing_details?.last_name || "",
      email: formData.billing_details?.email || "",
      job_title: formData.billing_details?.job_title || "",
      address_line_1: formData.billing_details?.address_line_1 || "",
      address_line_2: formData.billing_details?.address_line_2 || "",
      city: formData.billing_details?.city || "",
      state: formData.billing_details?.state || "",
      country: formData.billing_details?.country || "",
      zipcode: formData.billing_details?.zipcode || "",
    },
  });

  const onSubmit = (data) => {
    updateFormData({ billing_details: data });
    onComplete();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack mt={8}>
        <FormControl isInvalid={!!errors.ip_selection} pb={5} width="65%">
          <Text fontWeight="bold" pb={2}>
            IP Selection
          </Text>
          <Flex direction="row" justifyContent="space-between">
            <Controller
              name="ip_selection.partner_billing"
              control={control}
              render={({ field }) => (
                <Checkbox {...field}>Partner Billing</Checkbox>
              )}
            />

            <Controller
              name="ip_selection.customer_billing"
              control={control}
              render={({ field }) => (
                <Checkbox {...field}>Customer Billing</Checkbox>
              )}
            />
          </Flex>
          <FormErrorMessage>{errors.ip_selection?.message}</FormErrorMessage>
        </FormControl>

        <Text fontWeight="bold" pb={2}>
          Billing Contact Details
        </Text>

        <Flex gap={4} pb={2}>
          <FormControl isInvalid={!!errors.first_name} pb={2}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="First Name"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.first_name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.last_name} pb={2}>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Last Name"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.last_name?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex gap={4} pb={2}>
          <FormControl isInvalid={!!errors.email} pb={2}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Email Address"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.job_title} pb={2}>
            <Controller
              name="job_title"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Job Title"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.job_title?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Text fontWeight="bold">Billing Address</Text>

        <FormControl isInvalid={!!errors.address_line_1} pb={2}>
          <Controller
            name="address_line_1"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Address Line 1"
                bg="white"
                _placeholder={{ color: "black", fontWeight: "normal" }}
              />
            )}
          />
          <FormErrorMessage>{errors.address_line_1?.message}</FormErrorMessage>
        </FormControl>

        <Flex gap={4} pb={2}>
          <FormControl>
            <Controller
              name="address_line_2"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Address Line 2"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.city}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="City"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Stack direction="row" pb={5}>
          <FormControl isInvalid={!!errors.state}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Select state"
                  {...field}
                  bg="white"
                  icon={<Icon as={DropdownIcon} />}
                >
                  {/* Add state options here */}
                </Select>
              )}
            />
            <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.country}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Select country"
                  {...field}
                  bg="white"
                  icon={<Icon as={DropdownIcon} />}
                >
                  {/* Add country options here */}
                </Select>
              )}
            />
            <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.zipcode}>
            <Controller
              name="zipcode"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Zipcode"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.zipcode?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <Button
          type="submit"
          bg="#422AFB"
          color="white"
          borderRadius="md"
          _hover={{ bg: "#3b24da" }}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};

export default BillingDetailsForm;
