import React, { useEffect, useMemo } from "react";
import { Box, Text, Icon, Button } from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const OrderSuccessOrFail = ({
	isError,
	isSuccess,
	successMessage,
	errorMessage,
}) => {
	const location = useLocation();
	const params = useMemo(
		() => new URLSearchParams(location.search),
		[location]
	);

	useEffect(() => {
		if (isSuccess) {
			params.delete("create");
		}
	}, [isSuccess, params]);

	return (
		<Box
			textAlign="center"
			py={10}
			px={6}
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100%"
		>
			{isSuccess ? (
				<>
					<Icon as={CheckCircleIcon} w={24} h={24} color="green.400" />
					<Text fontSize="2xl" mt={4}>
						Order is Created Successfully
					</Text>
					<Text mt={2} color="gray.600">
						The contract was sent
						<Text as="span" fontWeight="bold">
							{successMessage}
						</Text>{" "}
						for signing
					</Text>
					<Text mt={4}>Thank You</Text>
					<Button
						mt={6}
						colorScheme="green"
						onClick={() => window.location.reload()}
					>
						Go Back
					</Button>
				</>
			) : (
				<>
					<Icon as={WarningIcon} w={24} h={24} color="red.400" />
					<Text fontSize="2xl" mt={4}>
						Error in Creating Order
					</Text>
					<Text mt={2} color="gray.600">
						{errorMessage}
					</Text>
					<Button
						mt={6}
						colorScheme="red"
						onClick={() => window.location.reload()}
					>
						Retry
					</Button>
				</>
			)}
		</Box>
	);
};

export default OrderSuccessOrFail;
