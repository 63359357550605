/* eslint-disable */
import React from "react";
import { Flex, Box, Divider, Center } from "@chakra-ui/react";

function OnboardForm({ children }) {
	return (
		<Flex
			width={"100%"}
			height={"100vh"}
			justifyContent={"start"}
			alignItems={"center"}
		>
			{/* <Flex flex={1} height={"100%"} alignItems={"center"}> */}
			<Box
				flex={1}
				width={"100%"}
				maxW={"container.md"}
				paddingX={16}
				paddingY={8}
				boxShadow="md"
				height={"100%"}
				overflowY={"auto"}
			>
				{children}
			</Box>

			{/* <Divider
				orientation="vertical"
				height={"100vh"}
				width={"2px"}
				display={{ base: "none", md: "block" }}
			/> */}

			{/* <Flex
				flex={1}
				justifyContent={"center"}
				alignItems={"center"}
				display={{ base: "none", md: "flex" }}
				height={"100%"}
			>
				Image
			</Flex> */}
		</Flex>
	);
}

export default OnboardForm;
