import React, { useEffect } from "react";
import {
	Box,
	SimpleGrid,
	Spinner,
	useColorModeValue,
	Center,
	Divider,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { DashBoardTable } from "components/table/Table";
import { useGetOrders } from "features/Orders/useGetOrders";
import { useGetQuotations } from "features/Billing/useGetQuotation";

const heads = [
	"Commitment TB",
	"Partner Billing",
	"Price Cents-Per-GB",
	"Product Category",
	"Product Class",
	"Product Name",
	"Sku",
	"Term Months",
	"White Label",
];

const Quotes = () => {
	const currentDate = new Date();
	const oneMonthBefore = new Date(currentDate);
	oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
	oneMonthBefore.setDate(1);
	const omb_date = oneMonthBefore.toISOString().split("T")[0];

	const location = useLocation();
	const params = useParams();
	const queryParams = new URLSearchParams(location.search);

	const term = queryParams.get("term");
	const commitment = queryParams.get("commitment");
	const productclass = queryParams.get("productClass");
	const branding = queryParams.get("brnading");
	const partnerbilling = queryParams.get("partnerBilling");
	const sku = queryParams.get("sku");

	// Fetch Data
	const {
		isLoading: fetchingOrders,
		quotes,
		results,
		refetch,
	} = useGetQuotations({
		term,
		commitment,
		productclass,
		branding,
		partnerbilling,
		sku,
	});

	useEffect(() => {
		refetch();
	}, [location.search, refetch]);

	// Chakra Color Mode
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

	return (
		<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
			{fetchingOrders ? (
				<Center h="25rem">
					<Spinner size="xl" color="blue.500" />
				</Center>
			) : (
				<>
					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="30px" mb="30px">
						<DashBoardTable heads={heads} data={quotes} />
					</SimpleGrid>
				</>
			)}
		</Box>
	);
};

export default Quotes;
