/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  let activeBgColor = useColorModeValue("blue.50", "blue.900");

  const { routes } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <React.Fragment key={index}>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt="18px"
              pb="12px"
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </React.Fragment>
        );
      } else if (route.layout === "/admin" || route.layout === "/auth") {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            <Box
              bg={
                activeRoute(route.path.toLowerCase())
                  ? brandColor
                  : "transparent"
              }
              borderRadius="lg"
              ml="0"
              marginRight={2}
            >
              <Box
                bg={
                  activeRoute(route.path.toLowerCase())
                    ? activeBgColor
                    : "transparent"
                }
                borderRadius="md"
                pl="10px"
                ml="0"
                mr="3px"
                py={2.5}
              >
                <Flex spacing="22px" align="center">
                  {route.icon && (
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                  )}
                  <Text
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : textColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                    flex="1"
                  >
                    {route.name}
                  </Text>
                </Flex>
              </Box>
            </Box>
          </NavLink>
        );
      }
    });
  };

  return createLinks(routes);
}

export default SidebarLinks;