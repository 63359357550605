import { Box } from "@chakra-ui/react";
import CreateEnrollmentForm from "components/OnboardForm/CreateEnrollmentForm";
import CreateOrderForm from "components/OnboardForm/CreateOrderForm";
import ProtectedRoute from "features/Authentication/ProtectedRoute";
import React from "react";
import {
	Redirect,
	Route,
	Switch,
} from "react-router-dom/cjs/react-router-dom.min";

export default function OnboardLayout() {
	return (
		// <ProtectedRoute>
		<Switch>
			{/* <Route path="/sign-up/orders" component={CreateOrderForm} /> */}
			<Route path="/sign-up" component={CreateEnrollmentForm} />
			{/* <Redirect from="/sign-up" to="/sign-up/orders" /> */}
		</Switch>
		// </ProtectedRoute>
	);
}
