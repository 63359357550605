// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	font-family: "DM Sans", sans-serif;
}

option {
	color: black;
}
/* For WebKit browsers like Chrome and Safari */
::-webkit-scrollbar {
	display: none;
}

/* For Mozilla Firefox */
::-moz-scrollbar {
	display: none;
}

/* For Microsoft Edge */
::-ms-scrollbar {
	display: none;
}

/* For Internet Explorer */
/* Note: This might not work for all versions of IE */
/* It's recommended to avoid targeting IE-specific styles if possible */
body {
	-ms-overflow-style: none;
}

.selected {
	background-color: rgba(0, 225, 206, 0.271) !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AACA;CACC,kCAAkC;AACnC;;AAEA;CACC,YAAY;AACb;AACA,+CAA+C;AAC/C;CACC,aAAa;AACd;;AAEA,wBAAwB;AACxB;CACC,aAAa;AACd;;AAEA,uBAAuB;AACvB;CACC,aAAa;AACd;;AAEA,0BAA0B;AAC1B,qDAAqD;AACrD,uEAAuE;AACvE;CACC,wBAAwB;AACzB;;AAEA;CACC,qDAAqD;AACtD","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap\");\nbody {\n\tfont-family: \"DM Sans\", sans-serif;\n}\n\noption {\n\tcolor: black;\n}\n/* For WebKit browsers like Chrome and Safari */\n::-webkit-scrollbar {\n\tdisplay: none;\n}\n\n/* For Mozilla Firefox */\n::-moz-scrollbar {\n\tdisplay: none;\n}\n\n/* For Microsoft Edge */\n::-ms-scrollbar {\n\tdisplay: none;\n}\n\n/* For Internet Explorer */\n/* Note: This might not work for all versions of IE */\n/* It's recommended to avoid targeting IE-specific styles if possible */\nbody {\n\t-ms-overflow-style: none;\n}\n\n.selected {\n\tbackground-color: rgba(0, 225, 206, 0.271) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
