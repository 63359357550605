import { useState, useEffect } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import ProductSelectionForm from "./forms/order/ProductSelectionForm";
import ProductConfigurationForm from "./forms/order/ProductConfigurationForm";
import BillingInformationForm from "./forms/order/BillingInformationForm";
import CustomerAgreementForm from "./forms/order/CustomerAgreementForm";
import OnboardForm from ".";
import { useGetEnrollment } from "features/Onboarding/useGetEnrollment";
import { useCreateEnrollment } from "features/Onboarding/useCreateEnrollment";
import AccountInformation from "./forms/order/AccountInformation";
import EnrollmentSuccessOrFail from "./forms/order/EnrollmentSuccessOrFail";
import axios from "axios";

const stepForm = [
	{ step: 1, form: AccountInformation },
	{ step: 2, form: ProductSelectionForm },
	{ step: 3, form: ProductConfigurationForm },
	{ step: 4, form: BillingInformationForm },
	{ step: 5, form: CustomerAgreementForm },
];

export default function CreateEnrollmentForm() {
	const [step, setStep] = useState(
		Number(sessionStorage.getItem("estep")) || 1
	);
	const [formData, setFormData] = useState(
		JSON.parse(sessionStorage.getItem("enrollmentData")) || {}
	);
	const [userAgent, setUserAgent] = useState("");

	const { isLoading: fetchingEnrollment, enrollment } = useGetEnrollment();

	const {
		isLoading: creatingEnrollment,
		createEnrollment,
		isSuccess,
		isError,
	} = useCreateEnrollment();

	// Fetch user's IP and browser information
	useEffect(() => {
		const getUserInfo = async () => {
			try {
				// Fetch user's IP address
				const ipResponse = await axios.get("https://api.ipify.org?format=json");
				const ipAddress = ipResponse.data.ip;

				// Get browser information from the navigator object
				const browserInfo = {
					appName: navigator.appName,
					appVersion: navigator.appVersion,
					userAgent: navigator.userAgent,
					platform: navigator.platform,
				};

				// Concatenate IP address and browser info into the user_agent field
				const userAgentString = `IP: ${ipAddress}, Browser: ${browserInfo.appName}, Version: ${browserInfo.appVersion}, User Agent: ${browserInfo.userAgent}, Platform: ${browserInfo.platform}`;

				// Store it in the state
				setUserAgent(userAgentString);
			} catch (error) {
				console.error("Error fetching IP or browser info:", error);
			}
		};

		getUserInfo();
	}, []);

	useEffect(() => {
		if (
			step === 1 &&
			(!formData || Object.keys(formData).length === 0) &&
			!fetchingEnrollment
		) {
			sessionStorage.setItem("estep", 1);
			sessionStorage.setItem("enrollmentData", JSON.stringify(enrollment));
			setFormData(enrollment);
		}

		if (isError) {
			setStep(1);
		}
	}, [formData, step, fetchingEnrollment, enrollment, isError]);

	const handleSubmit = () => {
		sessionStorage.removeItem("enrollmentData");
		sessionStorage.removeItem("estep");

		// Include the user_agent field in the submission
		createEnrollment({
			enrollmentData: {
				...formData,
				user_agent: userAgent, // Add user_agent to the payload
			},
		});
	};

	useEffect(() => {
		if (step === 6) {
			handleSubmit();
		} else {
			sessionStorage.setItem("enrollmentData", JSON.stringify(formData));
			sessionStorage.setItem("estep", step);
		}
	}, [step]);

	const updateFormData = (updatedData) => {
		setFormData((prevData) => ({
			...prevData,
			...updatedData,
		}));
	};

	console.log("formData", formData);

	return (
		<OnboardForm>
			{fetchingEnrollment ||
			creatingEnrollment ||
			Object.keys(formData).length === 0 ? (
				<Center height="100vh">
					<Spinner size="lg" />
				</Center>
			) : isSuccess || isError ? (
				<EnrollmentSuccessOrFail
					isError={isError}
					isSuccess={isSuccess}
					setStep={setStep}
				/>
			) : (
				stepForm.map((item) => {
					if (step === item.step) {
						const Form = item.form;
						return (
							<Form
								key={item.step}
								setStep={setStep}
								step={step}
								formData={formData}
								updateFormData={updateFormData}
								finalStep={5}
								isReadOnly={step !== 4 && step !== 5}
								isEnrollment={true}
							/>
						);
					}
					return null;
				})
			)}
		</OnboardForm>
	);
}
