import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
	Route,
	Switch,
	Redirect,
	BrowserRouter as Router,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import OnboardLayout from "layouts/onboard";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { Login } from "features/Authentication/Login";
import { DashBoardTable } from "components/table/Table";
import { PartnerIdProvider } from "contexts/PartnerIdContext";

const queryClient = new QueryClient();

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<QueryClientProvider client={queryClient}>
			<PartnerIdProvider>
				<React.StrictMode>
					<ThemeEditorProvider>
						<Router>
							<Switch>
								<Route path={`/admin`} component={AdminLayout} />
								<Route path={`/auth`} component={AuthLayout} />
								<Route path={`/sign-up`} component={OnboardLayout} />
								<Route path={`/login`} component={Login} />
								<Route path={`/rtl`} component={DashBoardTable} />
								<Redirect from="/" to="/admin" />
							</Switch>
						</Router>
					</ThemeEditorProvider>
				</React.StrictMode>
				<Toaster position="top-right" reverseOrder={false} />
			</PartnerIdProvider>
		</QueryClientProvider>
	</ChakraProvider>,

	document.getElementById("root")
);
