import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { getEnrollmentService } from "services/apiOnboard";
import { useLocation } from "react-router-dom";

export const useGetEnrollment = () => {
	const location = useLocation();

	// Create a URLSearchParams object from the location search
	const queryParams = new URLSearchParams(location.search);

	// Get the value of a specific query parameter

	const history = useHistory();
	const email = queryParams.get("email");
	const token = queryParams.get("token");
	const { data, isLoading } = useQuery({
		queryKey: ["enrollment"],
		queryFn: () => {
			if (email && token) {
				return getEnrollmentService(email, token);
			}
			throw new Error("Invalid enrollment link");
		},
		onError: (error) => {
			history.replace("/auth/sign-in");
			toast.error(error.message || "Failed to fetch enrollment.");
		},

		onSuccess: (res) => {
			console.log(res);
			try {
				const response = res?.data;
				if (response && res.status !== 202) {
					if (res.status === 401) {
						// localStorage.removeItem("Titan_Access");
						history.replace("/auth/sign-in");
						toast.error("Unauthorized access. Please login again.");
					} else {
						history.replace("/auth/sign-in");
						toast.error(res?.data?.message || "Internal server error.");
					}
				} else if (!response) {
					history.replace("/auth/sign-in");
					toast.error("Something went wrong");
				}
			} catch (err) {
				history.replace("/auth/sign-in");
				toast.error("Invalid link");
			}
		},
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	return {
		enrollment: data?.data?.data,
		isLoading,
	};
};
