// DiskModal.js
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  Select,
  Input,
  Flex,
  Icon,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash.svg";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { useState } from "react";

const DiskModal = ({ isOpen, onClose, disks, handleDiskChange, handleDeleteDisk, handleAddDisk }) => {
  const [selectedDiskIndex, setSelectedDiskIndex] = useState(disks.length - 1);

  const handleBackToDashboard = () => {
    onClose();
  };

  const handleSliderChange = (val) => {
    handleDiskChange(selectedDiskIndex, "storageCapacity", val);
  };

  const handleDiskSelection = (index) => {
    setSelectedDiskIndex(index);
  };

  return (
    <Drawer isOpen={isOpen} placement="right">
      <DrawerOverlay />
      <DrawerContent
        maxWidth="500px"
        borderRadius="md"
        p={8}
        m={8}
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="2xl" fontWeight="bold">Storage Information</Text>
          <Box
            as="button"
            onClick={onClose}
            p={2}
            borderRadius="md"
            _hover={{ bg: "gray.100" }}
          >
            <Icon as={CloseIcon} w={30} h={30} />
          </Box>
        </Flex>
        <Text mb={4}>Here you can find all the disk information for the Virtual Machine</Text>
        <DrawerBody>
          {disks.map((disk, index) => (
            <Box key={index} mb={4} onClick={() => handleDiskSelection(index)}>
              <Flex mb={4} gap={4} alignItems="center" width="100%">
                <Select
                  value={disk.storageType}
                  onChange={(e) => handleDiskChange(index, "storageType", e.target.value)}
                  bg="white"
                  borderRadius={5}
                  width="full"
                  icon={<DropdownIcon />}
                >
                  <option value="SSD">SSD</option>
                  <option value="HDD">HDD</option>
                </Select>
                <Input
                  value={disk.storageCapacity}
                  onChange={(e) => handleDiskChange(index, "storageCapacity", e.target.value)}
                  bg="white"
                  borderRadius={5}
                  width="full"
                  placeholder="Capacity (GB)"
                />
                <Box
                  bg="white"
                  borderRadius={5}
                  p={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => handleDeleteDisk(index)}
                >
                  <Icon as={TrashIcon} w={5} h={5} />
                </Box>
              </Flex>
            </Box>
          ))}

          <Flex justifyContent="space-between" alignItems="center" py={2}>
            <Text>0 GB</Text>
            <Slider
              value={disks[selectedDiskIndex]?.storageCapacity || 0}
              min={0}
              max={1024}
              step={10}
              onChange={handleSliderChange}
              colorScheme="purple"
              width="60%"
            >
              <SliderTrack bg="purple.100">
                <SliderFilledTrack bg="#422AFB" />
              </SliderTrack>
              <SliderThumb boxSize={5} />
            </Slider>
            <Text>1024 GB</Text>
          </Flex>
        </DrawerBody>

        <Flex width="100%">
          <Button
            bg="#EDEBFF"
            color="#422AFB"
            _hover={{ bg: "#EDEBFF" }}
            _active={{ bg: "#d2c7ff" }}
            variant="solid"
            mr={2}
            borderRadius={8}
            fontSize="sm"
            width="full"
            onClick={handleAddDisk}
          >
            Add New Disk
          </Button>
          <Button
            bg="#422AFB"
            color="white"
            border="1px solid #5c18e1"
            _hover={{ bg: "#422AFB" }}
            _active={{ bg: "#d2c7ff" }}
            variant="outline"
            borderRadius={8}
            fontSize="sm"
            width="full"
            onClick={handleBackToDashboard}
          >
            Back to Dashboard
          </Button>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};

export default DiskModal;  