import React, { useState } from "react";
// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
	// function handleSetPartnerId() {
	// 	setPartnerId(partnerIdState);
	// 	onClose();
	// }

	//   Chakra color mode
	let logoColor = useColorModeValue("navy.700", "white");

	return (
		<Flex align="left" direction="column">
			{/* TODO: Need to add logo */}
			<Flex align="center" justify="start" w="100%" h="100px" gap="8px">
				<Text
					ml={"33px"}
					fontSize="xl"
					fontWeight="bold"
					color={logoColor}
					align={"left"}
				>
					Dashboard
				</Text>
				{/* <Button onClick={onOpen}>
					<FaKey />
				</Button>
				<Modal
					initialFocusRef={initialRef}
					finalFocusRef={finalRef}
					isOpen={isOpen}
					onClose={onClose}
					size="xl"
					m={4}
					p={3}
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Set PartnerID</ModalHeader>
						<ModalCloseButton />
						<ModalBody p={5}>
							<FormControl isRequired>
								<FormLabel>PartnerID</FormLabel>
								<Input
									value={partnerIdState}
									onChange={(e) => setPartnerIdState(e.target.value)}
									placeholder="Enter PartnerId value..."
								/>
							</FormControl>
						</ModalBody>
						<ModalFooter>
							<Flex w="100%" justifyContent="flex-end">
								<Button
									onClick={() => {
										onClose();
									}}
									borderRadius={5}
									p={"10px 30px"}
									mr={2}
								>
									Cancel
								</Button>
								<Button
									colorScheme="brand"
									ml={2}
									borderRadius={5}
									p={"10px 40px"}
									onClick={handleSetPartnerId}
								>
									Save
								</Button>
							</Flex>
						</ModalFooter>
					</ModalContent>
				</Modal> */}
			</Flex>
			{/* <HorizonLogo h="26px" w="175px" my="32px" color={logoColor} /> */}
			{/* <HSeparator mb="20px" /> */}
		</Flex>
	);
}

export default SidebarBrand;
