import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { getFirewallsService } from "services/apiVm";
import { useHistory } from "react-router-dom";

export const useGetFirewalls = (filterObject) => {
    const history = useHistory();
    
    const { data, isLoading, refetch, isError } = useQuery({
        queryKey: ["firewalls", filterObject],
        queryFn: () => getFirewallsService(filterObject),
        onError: (error) => {
            history.replace("/auth/sign-in");
            toast.error("Failed to fetch firewalls. Please try again.");
        },
        onSuccess: (res) => {
            const response = res.data;
            if (response && res.status !== 202) {
                if (res.status === 401) {
                    localStorage.removeItem("Titan_Access");
                    history.replace("/auth/sign-in");
                    toast.error("Unauthorized access. Please login again.");
                } else if(res.status === 500){
                    toast.error("Internal server error.");
                }
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
    return {
        firewall: data?.data?.data?.firewall || null,
        isLoading,
        isError,
        refetch,
    };
};