import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  RadioGroup,
  Radio,
  Select,
  Icon,
  Button,
} from "@chakra-ui/react";
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/dropdown.svg';

const SystemSetup = ({ orderType }) => {
  const [selectedTerm, setSelectedTerm] = useState("");

  return (
    <Box py={5} width="100%">
      <Box >
        {orderType === "ACS" && (
          <>
            <Text fontSize="md" fontWeight="bold" mb={4}>
              Users and Term for {orderType}
            </Text>
            <RadioGroup onChange={setSelectedTerm} value={selectedTerm}>
              <Table variant="simple" mb={6}>
                <Thead bg="#F9F7F7">
                  <Tr>
                    <Th />
                    <Th>Term</Th>
                    <Th>Commitment</Th>
                    <Th>Price/GB</Th>
                  </Tr>
                </Thead>
                <Tbody bg="white">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Tr key={index}>
                      <Td>
                        <Radio value={`term-${index + 1}`} />
                      </Td>
                      <Td>Term {index + 1}</Td>
                      <Td>Commitment {index + 1}</Td>
                      <Td>${(10 + index).toFixed(2)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </RadioGroup>
            <Input
              placeholder="Who is going to be the end user?"
              mb={4}
              bg="white"
              width="100%"
              mx="auto"
              _placeholder={{ color: "black", fontWeight: "normal" }}
            />
            <Text fontSize="md" fontWeight="bold" mb={4}>
              S3
            </Text>
            <Flex direction="column" mb={6}>
              <Flex mb={4} justifyContent="space-between" width="100%">
                <Input
                  bg="white"
                  placeholder="Admin contact email"
                  width="48%"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
                <Input
                  bg="white"
                  placeholder="Admin Contact Name"
                  width="48%"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              </Flex>
              <Input
                bg="white"
                placeholder="Root Username"
                width="100%"
                mb={4}
                _placeholder={{ color: "black", fontWeight: "normal" }}
              />
              <Input
                bg="white"
                placeholder="Desired URL"
                width="100%"
                _placeholder={{ color: "black", fontWeight: "normal" }}
              />
            </Flex>
          </>
        )}
        {orderType === "Database" && (
          <>
            <Text fontSize="md" fontWeight="bold" mb={4}>
              Database Information
            </Text>
            <Select placeholder="Select Region" my={3} bg="white" icon={<Icon as={DropdownIcon} />}/>
            <Select placeholder="Select CPU Type" mb={3} bg="white" icon={<Icon as={DropdownIcon} />}/>
            <Select placeholder="Select CPU Generation" mb={3} bg="white" icon={<Icon as={DropdownIcon} />}/>
            <Select placeholder="Numbers and Cores" mb={6} bg="white" icon={<Icon as={DropdownIcon} />}/>
            <Text fontSize="md" fontWeight="bold" mb={3}>Memory and Storage</Text>
            <Select placeholder="Numbers and Cores" mb={3} bg="white" icon={<Icon as={DropdownIcon} />}/>
            <Select placeholder="Numbers and Cores" mb={3} bg="white" icon={<Icon as={DropdownIcon} />}/>
          </>
        )}
        {orderType === "VM" && (
          <>
            <Text fontSize="md" fontWeight="bold" mb={4}>
              CPU Information
            </Text>
            <Select placeholder="Select CPU Make" mb={3} bg="white" icon={<Icon as={DropdownIcon} />}/>
            <Select placeholder="Select CPU Generation" mb={3} bg="white" icon={<Icon as={DropdownIcon} />}/>
            <Select placeholder="Number of Cores" mb={6} bg="white" icon={<Icon as={DropdownIcon} />}/>
            
            <Text fontSize="md" fontWeight="bold" mb={4}>
              Memory and Storage
            </Text>
            <Select placeholder="Select RAM" mb={3} bg="white" icon={<Icon as={DropdownIcon} />}/>
            <Flex mb={6} gap={3}>
              <Select placeholder="Disk Type" mr={2} bg="white" icon={<Icon as={DropdownIcon} />}/>
              <Input placeholder="Capacity" bg="white" />
              <Button ml={2} bgColor="#CCDAFF" color="black" size="md" fontSize="sm" borderRadius="md" width="50%" _hover={{bgColor:"#CCDAFF"}} >Add new Disk</Button>
            </Flex>
            
            <Text fontSize="md" fontWeight="bold" mt={4} mb={2}>
              Miscellaneous
            </Text>
            <Select placeholder="Select Operating System" mb={3} bg="white" icon={<Icon as={DropdownIcon} />} />
          </>
        )}
      </Box>


    </Box>
  );
};

export default SystemSetup;