import axios from "axios";
import { BASE_API_URL, API_ROUTES } from "constants/api.constants";

export async function uploadFileService(file) {
	const url = `${BASE_API_URL}${API_ROUTES.UPLOAD_FILE}`;

	const formData = new FormData();
	formData.append("file", file);

	const response = await axios.post(url, formData, {
		headers: {
			Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
			"Content-Type": "multipart/form-data",
			"X-API-KEY": "5a5e8f63-0101-42dc-9ea0-ebbd6d44930e",
		},
	});
	return response;
}
