import React, { useEffect } from "react";
import {
	Box,
	SimpleGrid,
	Spinner,
	useColorModeValue,
	Center,
	Divider,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { DashBoardTable } from "components/table/Table";
import { useGetS3 } from "features/Inventory/useGetS3";

const heads = [
	"Account Number",
	"Account Name",
	"Product Category",
	"Product Sub Category",
	"Region",
	"Console URL",
	"API URL",
];

const placeType = {
	5: "url",
};

const Storage = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const account_number = queryParams.get("accountNumber");
	// Fetch Data
	const {
		isLoading: fetchingInventory,
		s3s,
		refetch,
	} = useGetS3({
		account_number,
	});

	useEffect(() => {
		refetch();
	}, [location.search, refetch]);

	// Chakra Color Mode
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

	return (
		<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
			{fetchingInventory ? (
				<Center h="25rem">
					<Spinner size="xl" color="blue.500" />
				</Center>
			) : (
				<>
					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="30px" mb="30px">
						<DashBoardTable
							heads={heads}
							data={s3s}
							isTagged={true}
							placeType={placeType}
							onRowClick={() => {}}
						/>
					</SimpleGrid>
				</>
			)} 
		</Box>
	);
};

export default Storage;
