import axios from "axios";
import { API_ROUTES } from "constants/api.constants";
import { BASE_API_URL } from "constants/api.constants";
import removeNullValuesAndFormat from "utils/removeNullAndFormat";

export async function getS3Service(filterObj) {
	try {
		let url = BASE_API_URL + API_ROUTES.GET_INVENTORY;
		let params = removeNullValuesAndFormat(filterObj);
		url += "?res=s3";
		if (params.length) {
			url += params.join("&");
		}
		const response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
			},
		});
		return response;
	} catch (error) {
		return error.response;
	}
}
