import axios from "axios";
import { INTERNAL_SERVER_ERROR_MESSAGE } from "constants/api.constants";
import { API_ROUTES } from "constants/api.constants";
import { BASE_API_URL } from "constants/api.constants";
import toast from "react-hot-toast";

export const loginUser = async (loginObj) => {
	try {
		const loginAPIUrl = BASE_API_URL + API_ROUTES.LOGIN;
		const response = await axios.post(loginAPIUrl, loginObj);
		const { status } = response;
		if (status === 202) {
			const { data } = response;
			const { message, success } = data;
			return { message, success };
		} else if (status === 401) {
			const { data } = response;
			const { message } = data;
			toast.error(message);
			return false;
		} else {
			toast.error(INTERNAL_SERVER_ERROR_MESSAGE);
			return false;
		}
	} catch (error) {
		const { response } = error || {};
		const { status } = response || {};
		if (status === 401) {
			toast.error("Invalid email or password");
			return false;
		} else {
			toast.error(INTERNAL_SERVER_ERROR_MESSAGE);
			return false;
		}
	}
};

export const verifyUserService = async (verifyObj) => {
	const url = BASE_API_URL + API_ROUTES.VERIFY;
	try {
		const response = await axios.get(
			`${url}?email=${verifyObj.email}&token=${verifyObj.token}`
		);
		const { data } = response;
		return data;
	} catch (error) {
		return error.response;
	}
};
