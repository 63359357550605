import { Tr, Td, Box, Button, Collapse, Center } from "@chakra-ui/react";
import { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { TbRadiusBottomLeft } from "react-icons/tb";
import { DashBoardTable } from "./Table";

export const TableRow = ({ data, rowIndex, placeType ,onRowClick }) => {
	const [openRows, setOpenRows] = useState({});

	const handleToggle = (id) => {
		setOpenRows((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};

	const isRowOpen = Object.keys(openRows).some(
		(key) => key.startsWith(`${rowIndex}-`) && openRows[key]
	);

	return (
		<>
			<Tr onClick={() => onRowClick(data)} _hover={{ cursor: "pointer", bg: "gray.100" }}> 
				{Object.keys(data).map((key, index) => (
					<Td key={index} className={isRowOpen ? "selected" : ""} border="1px solid #DFE5ED">
						<Center>
							{Array.isArray(data[key]) ? (
								<Box>
									<Button
										variantColor="blue"
										onClick={() => handleToggle(`${rowIndex}-${index}`)}
									>
										{openRows[`${rowIndex}-${index}`] ? (
											<FaCaretUp />
										) : (
											<FaCaretDown />
										)}
									</Button>
								</Box>
							) : data[key] ? (
								placeType !== undefined && placeType[index] === "url" ? (
									<a href={`https://${data[key]}`} style={{ color: "blue" }}>
										{data[key]}
									</a>
								) : (
									data[key]
								)
							) : data[key] === 0 ? (
								"0"
							) : (
								"N/A"
							)}
						</Center>
					</Td>
				))}
			</Tr>
			{Object.keys(data).map(
				(key, index) =>
					Array.isArray(data[key]) &&
					openRows[`${rowIndex}-${index}`] && (
						<Tr key={`${rowIndex}-${key}`}>
							<Td
								colSpan={Object.keys(data).length}
								padding={0}
								paddingLeft={"2.5rem"}
								style={{ position: "relative" }}
							>
								<TbRadiusBottomLeft
									fontSize={"2rem"}
									color="rgba(0, 0, 0, 0.601)"
									style={{ position: "absolute", left: "0" }}
								/>
								<Collapse
									in={openRows[`${rowIndex}-${index}`]}
									style={{
										marginTop: "1rem",
										boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.1)",
										borderRadius: "10px 0px 0px 10px",
										marginBottom: "0.5rem",
									}}
								>
									<DashBoardTable
										heads={
											typeof data[key][0] === "object"
												? Object.keys(data[key][0]).map((e) => e.toUpperCase())
												: [key.toUpperCase()]
										}
										data={
											typeof data[key][0] === "object"
												? data[key]
												: data[key].map((e) => ({ [key]: e }))
										}
									/>
								</Collapse>
							</Td>
						</Tr>
					)
			)}
		</>
	);
};
