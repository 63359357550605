import { useForm, Controller } from "react-hook-form";
import {
  Stack,
  FormControl,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Flex,Icon,
  Text,
} from "@chakra-ui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/dropdown.svg'

const schema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  company_name: z.string().min(1, { message: "Company name is required" }),
  industry: z.string().min(1, { message: "Industry is required" }),
  website: z.string().url({ message: "Invalid website URL" }).optional(),
  address_line_1: z.string().min(1, { message: "Address Line 1 is required" }),
  address_line_2: z.string().optional(),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  country: z.string().min(1, { message: "Country is required" }),
  zipcode: z.string().min(1, { message: "Zipcode is required" }),
});

const BasicInformationForm = ({ setStep, formData, updateFormData }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: zodResolver(schema),
    defaultValues: {
      email: formData.basic_information?.email || "",
      company_name: formData.basic_information?.company_name || "",
      industry: formData.basic_information?.industry || "",
      website: formData.basic_information?.website || "",
      address_line_1: formData.basic_information?.address_line_1 || "",
      address_line_2: formData.basic_information?.address_line_2 || "",
      city: formData.basic_information?.city || "",
      state: formData.basic_information?.state || "",
      country: formData.basic_information?.country || "",
      zipcode: formData.basic_information?.zipcode || "",
    },
  });

  const onSubmit = (data) => {
    updateFormData({ basic_information: data });
    setStep((prev) => prev + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack mt={8}>
        <Text fontWeight="bold" pb={2}>Basic Details</Text>

        <Flex gap={4} pb={2}>
          <FormControl isInvalid={!!errors.email}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Email Address"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.company_name}>
            <Controller
              name="company_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Company Name"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.company_name?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex gap={4} pb={5}>
          <FormControl isInvalid={!!errors.industry}>
            <Controller
              name="industry"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Industry"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.industry?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.website}>
            <Controller
              name="website"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Website"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.website?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Text fontWeight="bold" pb={2}>Mailing Address</Text>

        <FormControl isInvalid={!!errors.address_line_1} pb={2}>
          <Controller
            name="address_line_1"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Address Line 1"
                bg="white"
                _placeholder={{ color: "black", fontWeight: "normal" }}
              />
            )}
          />
          <FormErrorMessage>{errors.address_line_1?.message}</FormErrorMessage>
        </FormControl>

        <Flex gap={4} pb={2}>
          <FormControl>
            <Controller
              name="address_line_2"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Address Line 2"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.city}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="City"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Stack direction="row" pb={5}>
          <FormControl isInvalid={!!errors.state}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Select state"
                  {...field}
                  bg="white"
                  icon={<Icon as={DropdownIcon} />}
                >
                  {/* Add state options here */}
                </Select>
              )}
            />
            <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.country}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Select country"
                  {...field}
                  bg="white"
                  icon={<Icon as={DropdownIcon} />}
                >
                  {/* Add country options here */}
                </Select>
              )}
            />
            <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.zipcode}>
            <Controller
              name="zipcode"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Zipcode"
                  bg="white"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              )}
            />
            <FormErrorMessage>{errors.zipcode?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Button
          type="submit"
          bg="#422AFB"
          color="white"
          fontWeight="normal"
          borderRadius="md"
          _hover={{ bg: "#3b24da" }}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};

export default BasicInformationForm;