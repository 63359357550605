import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Box,
	FormControl,
	FormErrorMessage,
	Heading,
	Input,
	Select,
	Stack,
	Grid,
	Text,
	Flex,
	Button,
} from "@chakra-ui/react";
import NavigationButtons from "../../NavigationButtons";
import axios from "axios";
import { BASE_API_URL } from "constants/api.constants";
import { API_ROUTES } from "constants/api.constants";

const ProductConfigurationForm = ({
	setStep,
	formData,
	updateFormData,
	isReadOnly = false,
	step,
	finalStep,
	isEnrollment = false,
}) => {
	const formSchema = z
		.object({
			endUser: z.string().nonempty({ message: "End user is required" }),
			customerEmail: z.string().optional(),
			adminEmail: z.string().optional(),
			adminName: z.string().optional(),
			rootUsername: z.string().optional(),
			rootPassword: z.string().optional(),
			desiredURL: z.string().optional(),
		})
		.superRefine((data, ctx) => {
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (data.endUser === "customer") {
				if (!data.customerEmail) {
					ctx.addIssue({
						path: ["customerEmail"],
						message: "Customer email is required",
					});
				}
				if (!emailRegex.test(data.customerEmail)) {
					// Check if the adminEmail matches the email pattern
					ctx.addIssue({
						path: ["customerEmail"],
						message: "Invalid email address",
					});
				}
			}
			if (data.endUser === "partner" || isEnrollment) {
				if (!data.adminEmail) {
					ctx.addIssue({
						path: ["adminEmail"],
						message: "Admin email is required",
					});
				}
				if (!emailRegex.test(data.adminEmail)) {
					// Check if the adminEmail matches the email pattern
					ctx.addIssue({
						path: ["adminEmail"],
						message: "Invalid email address",
					});
				}
				if (!data.adminName) {
					ctx.addIssue({
						path: ["adminName"],
						message: "Admin name is required",
					});
				}
				if (!data.rootUsername) {
					ctx.addIssue({
						path: ["rootUsername"],
						message: "Root username is required",
					});
				}
				if (!data.rootPassword) {
					ctx.addIssue({
						path: ["rootPassword"],
						message: "Root password is required",
					});
				}
				if (!data.desiredURL) {
					ctx.addIssue({
						path: ["desiredURL"],
						message: "Desired URL is required",
					});
				}
			}
		});
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setError,
		clearErrors,
	} = useForm({
		resolver: zodResolver(formSchema),
		defaultValues: {
			endUser: formData.product_configuration?.end_user || "",
			customerEmail:
				formData.product_configuration?.customer_email_address || "",
			adminEmail:
				formData.product_configuration?.configuration?.admin_contact_email ||
				"",
			adminName:
				formData.product_configuration?.configuration?.admin_contact_name || "",
			rootUsername:
				formData.product_configuration?.configuration?.root_username || "",
			rootPassword:
				formData.product_configuration?.configuration?.root_password || "",
			desiredURL:
				formData.product_configuration?.configuration?.desired_url.split(
					"."
				)[0] || "",
		},
	});

	const selectedEndUser = watch("endUser");
	const desiredUrl = watch("desiredURL");
	const fullDesiredUrl = `${desiredUrl}.s3.${formData.product_selection?.region}.titancloudstorage.com`;

	const [urlChecked, setUrlChecked] = useState(false);
	const [isCheckingUrl, setIsCheckingUrl] = useState(false);
	const [urlAvailable, setUrlAvailable] = useState(false);

	useEffect(() => {
		setUrlChecked(false);
		setUrlAvailable(false);
		clearErrors("desiredURL");
	}, [desiredUrl]);

	const checkUrlAvailability = async () => {
		setIsCheckingUrl(true);
		clearErrors("desiredURL");

		try {
			// API call to check URL availability
			const response = await axios.get(
				`${BASE_API_URL}${API_ROUTES.CHECK_AVAILABILITY}?url=${fullDesiredUrl}`
			);
			const isAvailable = response.data?.data?.statusCode === 200;

			setUrlAvailable(isAvailable);
			if (isAvailable) {
				setUrlChecked(true);
			} else {
				setError("desiredURL", {
					type: "manual",
					message: "URL is not available",
				});
			}
		} catch (error) {
			setError("desiredURL", {
				type: "manual",
				message: "Failed to check URL availability. Please try again.",
			});
		} finally {
			setIsCheckingUrl(false);
		}
	};

	const onSubmit = async (data) => {
		// if (!isReadOnly && selectedEndUser === "partner" && !urlChecked) {
		// 	setError("desiredURL", {
		// 		type: "manual",
		// 		message: "Please check the URL for availability",
		// 	});
		// 	return;
		// }

		if (
			(isEnrollment && !urlChecked && selectedEndUser === "customer") ||
			(!isReadOnly && selectedEndUser === "partner" && !urlChecked)
		) {
			setError("desiredURL", {
				type: "manual",
				message: "Please check the URL for availability",
			});
			return;
		}

		if (isReadOnly || selectedEndUser === "customer" || urlAvailable) {
			const info = {
				product_type: "s3",
				end_user: data.endUser,
				customer_email_address: data.customerEmail,
				configuration: {
					admin_contact_email: data.adminEmail,
					admin_contact_name: data.adminName,
					root_username: data.rootUsername,
					root_password: data.rootPassword,
					desired_url: `${data.desiredURL}.s3.titancloudstorage.com`,
				},
			};
			updateFormData({
				product_configuration: info,
			});
			setStep((prev) => prev + 1);
		}
	};

	return (
		<form>
			<Stack spacing={8}>
				<Heading as="h1" size="lg" fontWeight="600">
					Product Configuration
				</Heading>
				<Box>
					<FormControl isDisabled={isReadOnly} isInvalid={!!errors.endUser}>
						<Controller
							name="endUser"
							control={control}
							render={({ field }) => (
								<Select
									value={selectedEndUser || ""}
									placeholder={
										selectedEndUser ? "" : "Who is going to be the end user?"
									}
									{...field}
								>
									<option value="partner">Partner</option>
									<option value="customer">Customer</option>
								</Select>
							)}
						/>
						<FormErrorMessage>{errors.endUser?.message}</FormErrorMessage>
					</FormControl>
				</Box>
				{selectedEndUser === "customer" && (
					<Box>
						<FormControl
							isDisabled={isReadOnly}
							isInvalid={!!errors.customerEmail}
						>
							<Controller
								name="customerEmail"
								control={control}
								render={({ field }) => (
									<Input
										type="email"
										placeholder="Customer Email Address"
										{...field}
									/>
								)}
							/>
							<FormErrorMessage>
								{errors.customerEmail?.message}
							</FormErrorMessage>
						</FormControl>
					</Box>
				)}
				{(selectedEndUser === "partner" || isEnrollment) && (
					<Box>
						<Heading as="h2" fontWeight="600" size="md" mb={2}>
							S3
						</Heading>
						<Grid templateColumns="repeat(2, 1fr)" gap={4} fontSize="12px">
							<FormControl
								isDisabled={
									isReadOnly && !isEnrollment && !selectedEndUser === "customer"
								}
								isInvalid={!!errors.adminEmail}
							>
								<Controller
									name="adminEmail"
									control={control}
									render={({ field }) => (
										<Input
											type="email"
											placeholder="Admin Contact Email"
											{...field}
										/>
									)}
								/>
								<FormErrorMessage>
									{errors.adminEmail?.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isDisabled={
									isReadOnly && !isEnrollment && !selectedEndUser === "customer"
								}
								isInvalid={!!errors.adminName}
							>
								<Controller
									name="adminName"
									control={control}
									render={({ field }) => (
										<Input
											type="text"
											placeholder="Admin Contact Name"
											{...field}
										/>
									)}
								/>
								<FormErrorMessage>{errors.adminName?.message}</FormErrorMessage>
							</FormControl>
							<FormControl
								isDisabled={
									isReadOnly && !isEnrollment && !selectedEndUser === "customer"
								}
								isInvalid={!!errors.rootUsername}
							>
								<Controller
									name="rootUsername"
									control={control}
									render={({ field }) => (
										<Input type="text" placeholder="Root Username" {...field} />
									)}
								/>
								<FormErrorMessage>
									{errors.rootUsername?.message}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isDisabled={
									isReadOnly && !isEnrollment && !selectedEndUser === "customer"
								}
								isInvalid={!!errors.rootPassword}
							>
								<Controller
									name="rootPassword"
									control={control}
									render={({ field }) => (
										<Input
											type="password"
											placeholder="Root Password"
											{...field}
										/>
									)}
								/>
								<FormErrorMessage>
									{errors.rootPassword?.message}
								</FormErrorMessage>
							</FormControl>
							<Box width="100%" gridColumn="span 2">
								<Flex direction="row" align="center" gap={3}>
									<FormControl
										isDisabled={
											(isReadOnly &&
												!isEnrollment &&
												!selectedEndUser === "customer") ||
											isCheckingUrl
										}
										isInvalid={!!errors.desiredURL}
										flex="1"
									>
										<Controller
											name="desiredURL"
											control={control}
											render={({ field }) => (
												<Input
													type="text"
													placeholder="Desired URL"
													{...field}
													width="100%"
												/>
											)}
										/>
										<FormErrorMessage>
											{errors.desiredURL?.message}
										</FormErrorMessage>
										{urlAvailable && (
											<Text color="green.500">URL is available!</Text>
										)}
									</FormControl>
									<Text whiteSpace="nowrap" fontSize="1rem">
										.s3.
										{formData.product_selection?.region}
										.titancloudstorage.com
									</Text>
									{((!isReadOnly && selectedEndUser === "partner") ||
										(isEnrollment && selectedEndUser === "customer")) && (
										<Button
											onClick={checkUrlAvailability}
											isLoading={isCheckingUrl}
											isDisabled={isCheckingUrl}
										>
											Check Availability
										</Button>
									)}
								</Flex>
							</Box>
						</Grid>
					</Box>
				)}
				<NavigationButtons
					step={step}
					setStep={setStep}
					finalStep={finalStep}
					onSubmit={handleSubmit(onSubmit)}
				/>
			</Stack>
		</form>
	);
};

export default ProductConfigurationForm;
