// chakra imports
import { Box, Flex, Stack, Button } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import LeadFocus from "components/modal/LeadModal";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import ManagementMenu from "./ManagementMenu";

// FUNCTIONS

function SidebarContent(props) {
	const { routes } = props;

	const managementRoute = routes.filter(
		(route) => route.path === "/storage" || route.path === "/database"
	  );	  

	const normalRoutes = routes.filter(
		(route) =>
			route.name !== "Logout" &&
			!managementRoute.some((sr) => sr.path === route.path) &&
			route.collapsible!==true
	);
	const logoutRoute = routes.filter((route) => route.name === "Logout");

	// SIDEBAR
	return (
		<Flex
			direction="column"
			height="100%"
			pt="16px"
			px="16px"
			borderRadius="30px"
		>
			<Brand />
			<Stack direction="column" mb="auto">
				<Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
					<Links routes={normalRoutes} />
					<ManagementMenu collapsible={managementRoute} routes={routes} />
					<Links routes={logoutRoute} />
				</Box>

				{/* <HSeparator h={0.5} /> */}
				{/* <Box>
					<LeadFocus title="Add Lead" content="Add a new task to the list" />
				</Box> */}
			</Stack>

			<Box mt="60px" mb="40px" borderRadius="30px">
				<SidebarCard />
			</Box>
		</Flex>
	);
}

export default SidebarContent;
