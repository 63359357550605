import React from "react";
import { Box, Text, Icon, Button } from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";

const EnrollmentSuccessOrFail = ({
	isError,
	isSuccess,
	successMessage,
	errorMessage,
}) => {
	return (
		<Box
			textAlign="center"
			py={10}
			px={6}
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100vh"
		>
			{isSuccess ? (
				<>
					<Icon as={CheckCircleIcon} w={24} h={24} color="green.400" />
					<Text fontSize="2xl" mt={4}>
						Enrollment Created Successfully
					</Text>
					<Text mt={2} color="gray.600">
						The enrollment was successfully created
						<Text as="span" fontWeight="bold">
							{successMessage}
						</Text>
						.
					</Text>
					<Text mt={4}>Thank You</Text>
				</>
			) : (
				<>
					<Icon as={WarningIcon} w={24} h={24} color="red.400" />
					<Text fontSize="2xl" mt={4}>
						Error in Creating Enrollment
					</Text>
					<Text mt={2} color="gray.600">
						{errorMessage}
					</Text>
					<Button
						mt={6}
						colorScheme="red"
						onClick={() => window.location.reload()}
					>
						Retry
					</Button>
				</>
			)}
		</Box>
	);
};

export default EnrollmentSuccessOrFail;
