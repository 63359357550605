import {
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Button,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import React, { useState } from "react";
import { DashBoardTable } from "components/table/Table";
import VnDetails from "./detailedview/VnDetails";
import { useGetVirtualNetworks } from "features/Vm/useGetVirtualNetworks";

// Define the headers
const heads = [
  "V Net ID",
  "Account Number",
  "Region",
  "IPv4 Start",
  "IPv4 End",
  "DHCP",
  "DHCP IP Start",
  "DHCP IP End",
];

const Vn = ({ isCreate, toggleCreate, toggleNavbar }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const { virtualNetwork, isLoading } = useGetVirtualNetworks(searchTerm);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    toggleNavbar(false);
  };

  const handleBackToTable = () => {
    setSelectedRow(null);
    toggleNavbar(true);
  };

  const formatVirtualNetworkDataForTable = (vn) => {
    return {
      "V Net ID": vn.vnetID,
      "Account Number": vn.accountNumber,
      "Region": vn.region,
      "IPv4 Start": vn.ipv4CIDR,
      "IPv4 End": vn.gatewayIPv4,
      "DHCP": vn.dhcpEnabled ? "Enabled" : "Disabled",
      "DHCP IP Start": vn.dhcpIPv6RangeStart,
      "DHCP IP End": vn.dhcpIPv6RangeEnd,
    };
  };

  const formattedDataForTable = virtualNetwork
    ? [formatVirtualNetworkDataForTable(virtualNetwork)]
    : [];

  return (
    <Box
      pt={
        isCreate ? { md: "45px" } : { base: "135px", md: "100px", xl: "100px" }
      }
      px={{ base: "15px", md: "15px" }}
    >
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        {!isCreate && !selectedRow && (
          <InputGroup width={{ base: "100%", md: "450px" }}>
            <InputLeftElement>
              <Icon as={SearchIcon} />
            </InputLeftElement>
            <Input
              placeholder="Search Account Number..."
              value={searchTerm}
              bg="white"
              color="navy.700"
              border="none"
              onChange={handleSearchChange}
              marginRight={4}
            />
          </InputGroup>
        )}
        {!selectedRow && (
          <Flex gap={4} justifyContent="flex-end" flex="1">
            <Button
              onClick={toggleCreate}
              variant="solid"
              size="md"
              px={"1.2rem"}
              py={"1.4rem"}
              borderRadius={5}
              bg={isCreate ? "#FF6060" : "#422AFB"}
              color="white"
              fontWeight="light"
              _hover={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.8 }}
              _active={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.6 }}
            >
              {isCreate ? "Cancel" : "Create New Virtual Network"}
            </Button>
          </Flex>
        )}
      </Flex>

      {selectedRow ? (
        <VnDetails data={selectedRow} onBack={handleBackToTable} />
      ) : isLoading ? (
        <div>Loading...</div>
      ) : !isCreate ? (
        <DashBoardTable
          heads={heads}
          data={formattedDataForTable || []}
          onRowClick={() => handleRowClick(virtualNetwork)}
        />
      ) : (
        <div></div>
      )}
    </Box>
  );
};

export default Vn;
