import { useCreateLead } from "features/Lead/useCreateLead";
import { IoCreateOutline } from "react-icons/io5";

import React, { useEffect, useState, useRef } from "react";
import {
	Box,
	Button,
	useDisclosure,
	ModalOverlay,
	Modal,
	Text,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	ModalFooter,
	Grid,
	Flex,
	FormErrorMessage,
} from "@chakra-ui/react";

export default function LeadFocus(props) {
	const { ...rest } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = React.useRef();
	const finalRef = React.useRef();

	const [campaignName, setCampaignName] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [email, setEmail] = useState("");
	const [estimatedStorage, setEstimatedStorage] = useState("");
	const [fingerprint, setFingerprint] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phone, setPhone] = useState("");
	const [preferredLanguage, setPreferredLanguage] = useState("");
	const [source, setSource] = useState("");
	const [title, setTitle] = useState("");
	const [estimatedSpend, setEstimatedSpend] = useState("");
	const [submitted, setSubmitted] = useState(false);

	const cleanUp = () => {
		setCampaignName("");
		setCompanyName("");
		setEmail("");
		setEstimatedStorage("");
		setFingerprint("");
		setFirstName("");
		setLastName("");
		setPhone("");
		setPreferredLanguage("");
		setSource("");
		setTitle("");
		setEstimatedSpend("");
	};

	const { isLoading: creatingLead, createLead } = useCreateLead(
		cleanUp,
		onClose
	);

	const handleCreateLead = (e) => {
		setSubmitted(true);
		if (
			!campaignName ||
			!companyName ||
			!email ||
			!estimatedStorage ||
			!fingerprint ||
			!firstName ||
			!lastName ||
			!phone ||
			!preferredLanguage ||
			!source ||
			!title
		) {
			return;
		}
		createLead({
			Campaign: campaignName,
			CompanyName: companyName,
			Email: email,
			EstMonthSpend: estimatedSpend,
			EstStorage: estimatedStorage,
			FirstName: firstName,
			LastName: lastName,
			Phone: phone,
			PrefLanguage: preferredLanguage,
			Source: source,
			Title: title,
		});
	};

	useEffect(() => {
		//
	}, []);

	return (
		<>
			<Button
				onClick={onOpen}
				// style={{
				// 	borderRadius: "6px",
				// 	padding: "13px 36px",
				// 	height: "auto",
				// 	border: "none",
				// 	cursor: "pointer",
				// }}
				colorScheme="teal"
				variant="outline"
				cursor="pointer"
				padding="1rem 5rem"
				gap={2}
				disabled={creatingLead}
				borderRadius={6}
			>
				{rest.title}
				<Text fontWeight={4} fontSize={"1.2rem"}>
					<IoCreateOutline />
				</Text>
			</Button>

			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
				onCloseComplete={() => {
					setSubmitted(false);
				}}
				size="xl"
				m={4}
				p={3}
				scrollBehavior="inside"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create New Lead</ModalHeader>
					<ModalCloseButton />
					<ModalBody p={5}>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl isRequired isInvalid={submitted && !campaignName}>
								<FormLabel>Campaign Name</FormLabel>
								<Input
									disabled={creatingLead}
									value={campaignName}
									onChange={(e) => setCampaignName(e.target.value)}
									placeholder="Enter Campaign Name"
								/>
								<FormErrorMessage>Campaign Name is Required !</FormErrorMessage>
							</FormControl>
							<FormControl isRequired isInvalid={submitted && !companyName}>
								<FormLabel>Company Name</FormLabel>
								<Input
									disabled={creatingLead}
									value={companyName}
									onChange={(e) => setCompanyName(e.target.value)}
									placeholder="Enter Company Name"
								/>
								<FormErrorMessage>Company Name is Required !</FormErrorMessage>
							</FormControl>
							<FormControl mt={4} isRequired isInvalid={submitted && !email}>
								<FormLabel>Email</FormLabel>
								<Input
									disabled={creatingLead}
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Enter Email Address"
								/>
								<FormErrorMessage>Email is Required !</FormErrorMessage>
							</FormControl>
							<FormControl
								mt={4}
								isRequired
								isInvalid={submitted && !estimatedSpend}
							>
								<FormLabel>Estimated Month Spend</FormLabel>
								<Input
									disabled={creatingLead}
									value={estimatedSpend}
									onChange={(e) => setEstimatedSpend(e.target.value)}
									placeholder="Enter Estimated Spend"
								/>
								<FormErrorMessage>
									Estimated Spend is Required !
								</FormErrorMessage>
							</FormControl>
							<FormControl
								mt={4}
								isRequired
								isInvalid={submitted && !estimatedStorage}
							>
								<FormLabel>Estimated Storage</FormLabel>
								<Input
									disabled={creatingLead}
									value={estimatedStorage}
									onChange={(e) => setEstimatedStorage(e.target.value)}
									placeholder="Enter Estimated Storage"
								/>
								<FormErrorMessage>
									Estimated Storage is Required !
								</FormErrorMessage>
							</FormControl>
							<FormControl
								mt={4}
								isRequired
								isInvalid={submitted && !fingerprint}
							>
								<FormLabel>Fingerprint</FormLabel>
								<Input
									disabled={creatingLead}
									value={fingerprint}
									onChange={(e) => setFingerprint(e.target.value)}
									placeholder="Enter FingerPrint"
								/>
								<FormErrorMessage>Fingerprint is Required !</FormErrorMessage>
							</FormControl>
							<FormControl
								mt={4}
								isRequired
								isInvalid={submitted && !firstName}
							>
								<FormLabel>First Name</FormLabel>
								<Input
									disabled={creatingLead}
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									placeholder="Enter First Name"
								/>
								<FormErrorMessage>First Name is Required !</FormErrorMessage>
							</FormControl>
							<FormControl mt={4} isRequired isInvalid={submitted && !lastName}>
								<FormLabel>Last Name</FormLabel>
								<Input
									disabled={creatingLead}
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									placeholder="Enter Last Name"
								/>
								<FormErrorMessage>Last Name is Required !</FormErrorMessage>
							</FormControl>
							<FormControl mt={4} isRequired isInvalid={submitted && !phone}>
								<FormLabel>Phone</FormLabel>
								<Input
									disabled={creatingLead}
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									placeholder="Enter First Name"
								/>
								<FormErrorMessage>Phone is Required !</FormErrorMessage>
							</FormControl>
							<FormControl
								mt={4}
								isRequired
								isInvalid={submitted && !preferredLanguage}
							>
								<FormLabel>Preferred Language</FormLabel>
								<Input
									disabled={creatingLead}
									value={preferredLanguage}
									onChange={(e) => setPreferredLanguage(e.target.value)}
									placeholder="Enter Preferred Language"
								/>
								<FormErrorMessage>
									Preferred Language is Required !
								</FormErrorMessage>
							</FormControl>
							<FormControl mt={4} isRequired isInvalid={submitted && !source}>
								<FormLabel>Source</FormLabel>
								<Input
									disabled={creatingLead}
									value={source}
									onChange={(e) => setSource(e.target.value)}
									placeholder="Enter Source"
								/>
								<FormErrorMessage>Source is Required !</FormErrorMessage>
							</FormControl>
							<FormControl mt={4} isRequired isInvalid={submitted && !title}>
								<FormLabel>Title</FormLabel>
								<Input
									disabled={creatingLead}
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									placeholder="Enter title"
								/>
								<FormErrorMessage>Title is Required !</FormErrorMessage>
							</FormControl>
						</Grid>
					</ModalBody>
					<ModalFooter>
						<Flex w="100%" justifyContent="flex-end">
							<Button
								onClick={() => {
									cleanUp();
									onClose();
								}}
								disabled={creatingLead}
								borderRadius={5}
								p={"10px 30px"}
								mr={2}
							>
								Cancel
							</Button>
							<Button
								colorScheme="brand"
								ml={2}
								borderRadius={5}
								p={"10px 40px"}
								onClick={handleCreateLead}
								disabled={creatingLead}
							>
								Save
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
