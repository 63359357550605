import {
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Button,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import React, { useState } from "react";
import { DashBoardTable } from "components/table/Table";
import VnDetails from "./detailedview/VnDetails";

const heads = [
  "V Net ID",
  "Account Number",
  "Region",
  "IPv4 Start",
  "IPv4 End",
  "DHCP",
  "DHCP IP Start",
  "DHCP IP End",
];

const sampleData = [
  {
    vNetId: "VNET123",
    accountNumber: "ACC123456",
    region: "US-West",
    ipv4Start: "192.168.0.1",
    ipv4End: "192.168.0.254",
    dhcp: "Enabled",
    dhcpIpStart: "192.168.0.10",
    dhcpIpEnd: "192.168.0.50",
  },
  {
    vNetId: "VNET456",
    accountNumber: "ACC654321",
    region: "EU-Central",
    ipv4Start: "10.0.0.1",
    ipv4End: "10.0.0.254",
    dhcp: "Disabled",
    dhcpIpStart: "N/A",
    dhcpIpEnd: "N/A",
  },
  {
    vNetId: "VNET789",
    accountNumber: "ACC987654",
    region: "AP-South",
    ipv4Start: "172.16.1.1",
    ipv4End: "172.16.1.254",
    dhcp: "Enabled",
    dhcpIpStart: "172.16.1.10",
    dhcpIpEnd: "172.16.1.50",
  },
];

const Vn = ({ isCreate, toggleCreate, toggleNavbar }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  // const [isFirewallCreated, setIsFirewallCreated] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    toggleNavbar(false);
  };

  const handleBackToTable = () => {
    setSelectedRow(null);
    toggleNavbar(true);
  };

  // const handleFirewallCreationComplete = () => {
  //     toggleCreate();
  //     setIsFirewallCreated(true);
  // };

  return (
    <>
      <Box
        pt={
          isCreate
            ? { md: "45px" }
            : { base: "135px", md: "100px", xl: "100px" }
        }
        px={{ base: "15px", md: "15px" }}
      >
        <Flex justifyContent="space-between" mb={4} alignItems="center">
          {!isCreate && !selectedRow && (
            <InputGroup width={{ base: "100%", md: "450px" }}>
              <InputLeftElement>
                <Icon as={SearchIcon} />
              </InputLeftElement>
              <Input
                placeholder="Search Account Number..."
                value={searchTerm}
                bg="white"
                color="navy.700"
                border="none"
                onChange={handleSearchChange}
                marginRight={4}
              />
            </InputGroup>
          )}
          {!selectedRow && (
          <Flex gap={4} justifyContent="flex-end" flex="1">
            <Button
              onClick={toggleCreate}
              variant="solid"
              size="md"
              px={"1.2rem"}
              py={"1.4rem"}
              borderRadius={5}
              bg={isCreate ? "#FF6060" : "#422AFB"}
              color="white"
              fontWeight="light"
              _hover={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.8 }}
              _active={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.6 }}
            >
              {isCreate ? "Cancel" : "Create New Virtual Network"}
            </Button>
          </Flex>
          )}
        </Flex>

        {selectedRow ? (
          <VnDetails data={selectedRow} onBack={handleBackToTable} />
        ) : !isCreate ? (
          <DashBoardTable
            heads={heads}
            data={sampleData}
            onRowClick={handleRowClick}
          />
        ) : (
          <div></div>
        )}
      </Box>
    </>
  );
};

export default Vn;