import React, { useEffect, useState } from "react";
import {
	FormControl,
	FormLabel,
	Heading,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	FormErrorMessage,
	Flex,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import NavigationButtons from "../../NavigationButtons";
import { zodResolver } from "@hookform/resolvers/zod";
import { getOrderQuote } from "services/apiCreateOrder";

const schema = z.object({
	product_type: z.string().min(1, { message: "Product type is required" }),
	region: z.string().min(1, { message: "Region selection is required" }),
	product_id: z
		.string()
		.min(1, { message: "Term is required" })
		.transform((val) => {
			return parseInt(val);
		}),
});

const ProductSelectionForm = ({
	setStep,
	formData,
	updateFormData,
	isReadOnly = false,
	step,
	finalStep,
}) => {
	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
		defaultValues: {
			product_type: formData.product_selection?.product_type || "",
			region: formData.product_selection?.region || "",
			product_id: formData.product_selection?.product_id || 0,
		},
	});

	const [products, setProducts] = useState([]);
	const productType = watch("product_type");
	const region = watch("region");

	useEffect(() => {
		if (productType && region) {
			const fetchData = async () => {
				try {
					const reqObj = {
						region: region,
						producttype: productType,
						ContactID: "any",
					  };

					const response = await getOrderQuote(reqObj);
					if (response.data.success) {
						setProducts(response.data.data.products);
					} else {
						console.error("Error fetching order quote:", response);
					}
				} catch (error) {
					console.error("Error fetching data", error);
				}
			};

			fetchData();
		}
	}, [productType, region]);


	const onSubmit = (data) => {
		updateFormData({
			product_selection: data,
		});
		setStep((prev) => prev + 1);
	};

	const product_id = watch("product_id");

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={8}>
				<Heading as="h2" size="lg" mb={6}>
					Product Selection
				</Heading>

				<FormControl isDisabled={isReadOnly} isInvalid={!!errors.product_type}>
					<FormLabel>Product Type</FormLabel>
					<Controller
						name="product_type"
						control={control}
						render={({ field }) => (
							<Select placeholder="Product Type" {...field}>
								<option value="s3">S3</option>
								<option value="vm" disabled style={{ color: "gray" }}>
									VM (Coming Soon)
								</option>
							</Select>
						)}
					/>
					<FormErrorMessage>{errors.product_type?.message}</FormErrorMessage>
				</FormControl>

				<FormControl isDisabled={isReadOnly} isInvalid={!!errors.region}>
					<FormLabel>Region Selection</FormLabel>
					<Controller
						name="region"
						control={control}
						render={({ field }) => (
							<Select placeholder="Region Selection" {...field}>
								<option value="us-multi-region">us-multi-region</option>
								<option value="us-south-1">us-south-1</option>
								<option value="us-west-1">us-west-1</option>
							</Select>
						)}
					/>
					<FormErrorMessage>{errors.region?.message}</FormErrorMessage>
				</FormControl>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr backgroundColor={"#F9F7F7"} height={"2rem"}>
							<Th></Th>
							<Th>Term</Th>
							<Th>Commitment</Th>
							<Th>Price/GB</Th>
						</Tr>
					</Thead>
					<Tbody>
						{products.map((product) => (
							<Tr key={product.sku}>
								<Td>
									<RadioGroup
										value={String(product_id)}
										isDisabled={isReadOnly}
										onChange={(value) => setValue("product_id", Number(value))}
									>
										<Radio value={String(product.sku)}></Radio>
									</RadioGroup>
								</Td>
								<Td>{`${product.termMonths} Months`}</Td>
								<Td>{product.commitmentTB}</Td>
								<Td>{product.priceCentsPerGB}</Td>
							</Tr>
						))}
					</Tbody>
					<FormErrorMessage>{errors.product_id?.message}</FormErrorMessage>
				</Table>
				<NavigationButtons
					step={step}
					setStep={setStep}
					finalStep={finalStep}
					onSubmit={handleSubmit(onSubmit)}
				/>
			</Stack>
		</form>
	);
};

export default ProductSelectionForm;
