import { Box, Button, Center, Text, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

export const Logout = () => {
	const history = useHistory();

	const [time, setTime] = useState(3);
	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.removeItem("Titan_Access");
			toast.success("Logged out successfully.");
			history.replace("/auth/sign-in");
		}, 3000);
		const interval = setInterval(() => {
			setTime((prev) => {
				if (prev === 0) {
					clearInterval(interval);
					return prev;
				}
				return prev - 1;
			});
		}, 1000);

		return () => {
			clearInterval(interval);
			clearTimeout(timer);
		};
	}, [history]);

	function handleClick() {
		history.replace("/admin/orders");
		toast.error("Logout cancelled.");
	}

	return (
		<Box position="relative">
			<Center height={"100vh"}>
				<Flex direction={"column"} gap={5} alignItems={"center"}>
					<Text fontSize="2xl">Logging out in {time}</Text>
					{/* <Spinner
						thickness="4px"
						speed="0.65s"
						emptyColor="gray.200"
						color="blue.500"
						size="xl"
					/> */}
					<Button
						onClick={handleClick}
						colorScheme="red"
						variant="outline"
						width={"10rem"}
						border={"2px"}
					>
						Cancel
					</Button>
				</Flex>
			</Center>
		</Box>
	);
};
