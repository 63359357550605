import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { createLeadService } from "services/apiLead";

export const useCreateLead = (cleanUp, onClose) => {
	const history = useHistory();
	const { isLoading, mutate: createLead } = useMutation({
		mutationKey: ["createLead"],
		mutationFn: (lead) => createLeadService(lead),
		onSuccess: (response) => {
			if (!response) {
				toast.error("Failed to create lead");
				return;
			}
			if (response.status === 200) {
				toast.success("Lead created successfully");
				cleanUp();
				onClose();
			} else {
				if (response.status === 401) {
					localStorage.removeItem("Titan_Access");
					history.push("/auth");
				} else {
					toast.error(response.data.message);
					cleanUp();
					onClose();
				}
			}
		},
	});

	return {
		isLoading,
		createLead,
	};
};
