import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
	const history = useHistory();

	const token = localStorage.getItem("Titan_Access");
	useEffect(() => {
		if (!token) {
			setTimeout(() => {
				toast.error("Please login to access this page.");
				history.replace("/auth/sign-in");
			}, 1000);
		}
	}, [token, history]);
	if (!token)
		return (
			<Box>
				<Center height={"100vh"}>
					<Text fontSize="2xl">Redirecting to login...</Text>
					<Spinner
						thickness="4px"
						speed="0.65s"
						emptyColor="gray.200"
						color="blue.500"
						size="xl"
					/>
				</Center>
			</Box>
		);
	return children;
};

export default ProtectedRoute;
