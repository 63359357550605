import React, { useEffect, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Select, FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_API_URL, API_ROUTES } from "constants/api.constants";

const URL = `${BASE_API_URL}${API_ROUTES.GET_LOCATION}`;

const fetchCountries = async () => {
	const { data } = await axios.get(URL);
	return data?.data.map((country) => ({
		id: country.id,
		code: country.code,
		name: country.name,
	}));
};

const fetchStates = async (countryId) => {
	const { data } = await axios.get(`${URL}?country_id=${countryId}`);
	return data?.data.map((state) => ({
		id: state.id,
		name: state.name,
	}));
};

const LocationSelector = ({
	isReadOnly = false,
	defaultCountry,
	defaultState,
	defaultCity,
}) => {
	const {
		control,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	const country = watch("country", defaultCountry);
	const state = watch("state", defaultState);
	const city = watch("city", defaultCity);

	const isInitialRender = useRef(true);

	const {
		data: countries,
		isLoading: isCountriesLoading,
		isError: isCountriesError,
	} = useQuery("countries", fetchCountries, { refetchOnWindowFocus: false });

	const {
		data: states,
		isLoading: isStatesLoading,
		isError: isStatesError,
		refetch: refetchStates,
	} = useQuery(
		["states", country],
		() => {
			const selectedCountry = countries?.find((c) => c.code === country);
			if (selectedCountry) {
				return fetchStates(selectedCountry.id);
			}
			return [];
		},
		{ enabled: !!country && countries?.length > 0, refetchOnWindowFocus: false }
	);

	// Prevent initial refetch when defaults are set
	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
			return;
		}

		if (country && countries?.length > 0) {
			refetchStates();
		}
	}, [country, countries, refetchStates]);

	useEffect(() => {
		if (state && states?.length > 0 && !isInitialRender.current) {
			setValue("city", defaultCity || "");
		}
	}, [state, states, setValue, defaultCity]);

	useEffect(() => {
		// Only set default values if it's the initial render
		if (isInitialRender.current) {
			setValue("state", defaultState?.name || "");
			setValue("city", defaultCity?.name || "");
		}
	}, [defaultState, defaultCity, setValue]);

	return (
		<>
			<FormControl isDisabled={isReadOnly} isInvalid={!!errors.country}>
				<Controller
					name="country"
					control={control}
					render={({ field }) => (
						<Select
							placeholder="Country"
							{...field}
							value={field.value || ""}
							onChange={(e) => {
								const selectedCountryCode = e.target.value;
								field.onChange(selectedCountryCode);
							}}
							isLoading={isCountriesLoading}
							isDisabled={isCountriesError || isCountriesLoading || isReadOnly}
						>
							{countries?.map((c) => (
								<option key={c.id} value={c.code}>
									{c.name}
								</option>
							))}
						</Select>
					)}
				/>
				<FormErrorMessage>{errors.country?.message}</FormErrorMessage>
			</FormControl>

			<FormControl isDisabled={isReadOnly} isInvalid={!!errors.state}>
				<Controller
					name="state"
					control={control}
					render={({ field }) => (
						<Select
							placeholder="State"
							{...field}
							value={field.value || ""}
							onChange={(e) => {
								const selectedStateName = e.target.value;
								field.onChange(selectedStateName);
							}}
							isLoading={isStatesLoading}
							isDisabled={
								!country || isStatesError || isStatesLoading || isReadOnly
							}
						>
							{states?.map((s) => (
								<option key={s.id} value={s.name}>
									{s.name}
								</option>
							))}
						</Select>
					)}
				/>
				<FormErrorMessage>{errors.state?.message}</FormErrorMessage>
			</FormControl>

			<FormControl isDisabled={isReadOnly} isInvalid={!!errors.city}>
				<Controller
					name="city"
					control={control}
					render={({ field }) => (
						<Input
							placeholder="City"
							{...field}
							value={field.value || ""}
							onChange={(e) => {
								const cityName = e.target.value;
								field.onChange(cityName);
							}}
							isDisabled={!state || isReadOnly}
						/>
					)}
				/>
				<FormErrorMessage>{errors.city?.message}</FormErrorMessage>
			</FormControl>
		</>
	);
};

export default LocationSelector;
