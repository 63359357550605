import { useQuery } from "react-query";
import { getUsagesService } from "services/apiBilling";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

export const useGetBillingUsage = (filterObj) => {
	const history = useHistory();
	const { data, refetch, isLoading } = useQuery({
		queryKey: ["billingUsage"],
		queryFn: () => getUsagesService(filterObj),
		onError: (error) => {
			history.replace("/auth/sign-in");
			toast.error("Failed to fetch orders.");
		},
		onSuccess: (res) => {
			try {
				const response = res.data;
				if (response && res?.status !== 202) {
					if (res?.status === 401) {
						localStorage.removeItem("Titan_Access");
						history.replace("/auth/sign-in");
						toast.error("Unauthorized access. Please login again.");
					} else {
						toast.error(res?.data?.message || "Internal server error.");
					}
				}
			} catch (err) {
				toast.error("Error Occured : Data not fetched");
			}
		},
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	return {
		billingUsage: data?.data?.data?.usages || [],
		results: data?.data?.data?.results || 0,
		isLoading,
		refetch,
	};
};
