import {
	Box,
	Flex,
	InputGroup,
	InputLeftElement,
	Input,
	Icon,
	Button,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import React, { useState } from "react";
import { DashBoardTable } from "components/table/Table";
import VmDetails from "./detailedview/VmDetails";

const heads = [
	"Account Number",
	"Name",
	"Region",
	"CPU",
	"CPU Cores",
	"RAM",
	"Operating System",
];

const sampleData = [
	{
		"Account Number": "123456",
		"Name": "John Doe",
		"Region": "US-East",
		"CPU": "Intel Xeon",
		"CPU Cores": 4,
		"RAM": "16",
		"Operating System": "Windows Server 2019",
	},
	{
		"Account Number": "789012",
		"Name": "Jane Smith",
		"Region": "EU-West",
		"CPU": "AMD Ryzen",
		"CPU Cores": 8,
		"RAM": "32",
		"Operating System": "Ubuntu 20.04",
	},
];


const Vm = ({ isCreate, toggleCreate ,toggleNavbar}) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedRow, setSelectedRow] = useState(null);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleRowClick = (rowData) => {
        setSelectedRow(rowData);
        toggleNavbar(false);
      };

      const handleBackToTable = () => {
        setSelectedRow(null);
        toggleNavbar(true);
      };

	return (
		<Box pt={isCreate ? { md: "45px" } : { base: "135px", md: "100px", xl: "100px" }} px={{ base: "15px", md: "15px" }}>
			<Flex justifyContent="space-between" mb={4} alignItems="center">
				{!isCreate && !selectedRow && (
					<InputGroup width={{ base: "100%", md: "450px" }}>
						<InputLeftElement>
							<Icon as={SearchIcon} />
						</InputLeftElement>
						<Input
							placeholder="Search Account Number..."
							value={searchTerm}
							bg="white"
							color="navy.700"
							border="none"
							onChange={handleSearchChange}
							marginRight={4}
						/>
					</InputGroup>
				)}
				{!selectedRow && (
					<Flex gap={4} justifyContent="flex-end" flex="1">
						<Button
							onClick={toggleCreate}
							variant="solid"
							size="md"
							px={"1.2rem"}
							py={"1.4rem"}
							borderRadius={5}
							bg={isCreate ? "#FF6060" : "#422AFB"}
							color="white"
							fontWeight="light"
							_hover={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.8 }}
							_active={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.6 }}
						>
							{isCreate ? "Cancel" : "Create New VM"}
						</Button>
					</Flex>
				)}
			</Flex>
			{selectedRow ? (
				<VmDetails data={selectedRow} onBack={handleBackToTable} />
			) : !isCreate? (
				<DashBoardTable heads={heads} data={sampleData} onRowClick={handleRowClick} />
			) : <div></div>}
		</Box>
	);
};

export default Vm;