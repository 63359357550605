import { Box, Text, Divider } from "@chakra-ui/react";

const OrderSummary= () => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      ml="2rem"
      bg="#F7FAFC"
      minW={300}
    >
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Cost Summary
      </Text>

      <Text fontSize="md" fontWeight="semibold" color="gray.500" mb={2}>
        System Setup Cost
      </Text>

      <Box mb={4}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Text>Intel i9 10th Gen</Text>
          <Text fontWeight="semibold">$121.00</Text>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Text>Disk 1TB x 2</Text>
          <Text fontWeight="semibold">$280.00</Text>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Text>32GB RAM</Text>
          <Text fontWeight="semibold">$140.00</Text>
        </Box>
      </Box>

      <Divider borderColor="gray.300" mb={4} />

      <Box display="flex" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold">
          Total Cost
        </Text>
        <Text fontSize="xl" fontWeight="bold">
          $540.00
        </Text>
      </Box>
    </Box>
  );
};

export default OrderSummary;
