// PartnerIdContext.js

import React, { createContext, useState, useContext } from "react";
const queryParams = new URLSearchParams(window.location.search);

const PartnerContext = React.createContext({
	partnerId: "",
	setName: () => {},
});

// Step 1: Create a context
const PartnerIdContext = createContext(PartnerContext);

// Step 2: Create a provider component
export const PartnerIdProvider = ({ children }) => {
	const queryParams = new URLSearchParams(window.location.search);
	const urlPartner = queryParams.get("partnerId");

	const [partnerId, setPartnerId] = useState(urlPartner || "");

	// Define a state-changing function
	const setPartnerIdValue = (newPartnerId) => {
		setPartnerId(newPartnerId);
	};

	return (
		<PartnerIdContext.Provider
			value={{ partnerId, setPartnerId: setPartnerIdValue }}
		>
			{children}
		</PartnerIdContext.Provider>
	);
};

// Step 3: Create a custom hook to consume the context
export const usePartnerId = () => {
	const context = useContext(PartnerIdContext);
	if (!context) {
		throw new Error("usePartnerId must be used within a PartnerIdProvider");
	}
	return context;
};
