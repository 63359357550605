import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getS3Service } from "services/apiS3";

export const useGetS3 = (filterObj) => {
	const history = useHistory();
	const { data, isLoading, refetch } = useQuery({
		queryKey: ["s3s"],
		queryFn: () => getS3Service(filterObj),
		onError: (error) => {
			history.replace("/auth/sign-in");
			toast.error("Failed to fetch s3s.");
		},
		onSuccess: (res) => {
			try {
				const response = res.data;
				if (response && res?.status !== 202) {
					if (res?.status === 401) {
						history.replace("/auth/sign-in");
						toast.error("Unauthorized access. Please login again.");
					} else {
						toast.error(res?.data?.message || "Internal server error.");
					}
				}
			} catch (err) {
				toast.error("Error Occured : Data not fetched");
			}
		},
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	return {
		s3s: data?.data?.data?.s3_storage || [],
		isLoading,
		refetch,
	};
};
