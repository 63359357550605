import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { getVmService } from "services/apiVm";
import { useHistory } from "react-router-dom";

export const useGetVirtualMachines = (filterObject) => {
  const history = useHistory();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["virtualMachines", filterObject],
    queryFn: () => getVmService(filterObject),
    onError: (error) => {
      const statusCode = error.response?.status || 500;
      if (statusCode === 401) {
        localStorage.removeItem("Titan_Access");
        history.replace("/auth/sign-in");
        toast.error("Unauthorized access. Please log in again.");
      } else {
        toast.error("Failed to fetch virtual machines.");
      }
    },
    onSuccess: (res) => {
      const response = res.data;
      if (res.status !== 200) {
        if (res.status === 401) {
          localStorage.removeItem("Titan_Access");
          history.replace("/auth/sign-in");
          toast.error("Unauthorized access. Please login again.");
        } else if(res.status === 500) {
          toast.error("Internal server error.");
        }
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    virtualMachines: data?.data?.virtualMachines || [],
    results: data?.data?.results || 0,
    isLoading,
    refetch,
  };
};
