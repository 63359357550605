import React, { useEffect } from "react";
import {
	FormControl,
	Input,
	FormErrorMessage,
	Heading,
	Radio,
	RadioGroup,
	Stack,
	Select,
	Grid,
	Box,
	Flex,
	Button,
	Text,
} from "@chakra-ui/react";
import { z } from "zod";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import NavigationButtons from "../../NavigationButtons";
import LocationSelector from "../../LocationSelector";
import axios from "axios";
import { BASE_API_URL } from "constants/api.constants";
import { API_ROUTES } from "constants/api.constants";

// Define the validation schema using zod
const formSchema = z
	.object({
		customerType: z.string().nonempty({ message: "Customer type is required" }),
		accountNumber: z.string().optional(),
		distributor: z.string().nonempty({ message: "Distributor is required" }),
		email: z
			.string()
			.nonempty("Email is required")
			.email({ message: "Enter a valid email" }),
		companyName: z.string().nonempty({ message: "Company name is required" }),
		industry: z.string().nonempty({ message: "Industry is required" }),
		website: z.string().nonempty({ message: "Website is required" }),
		addressLine1: z
			.string()
			.nonempty({ message: "Address line 1 is required" }),
		addressLine2: z.string().optional(),
		city: z.string().nonempty({ message: "City is required" }),
		state: z.string().nonempty({ message: "State is required" }),
		country: z.string().nonempty({ message: "Country is required" }),
		zipCode: z.string().nonempty({ message: "Zip code is required" }),
	})
	.superRefine((data, ctx) => {
		if (data.customerType === "existing") {
			if (!data.accountNumber) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Account number is required",
					path: ["accountNumber"],
				});
			}
		}
	});

const AccountInformation = ({
	setStep,
	formData,
	updateFormData,
	isReadOnly = false,
	step,
	finalStep,
	isEnrollment = false,
}) => {
	const methods = useForm({
		resolver: zodResolver(formSchema),
		defaultValues: {
			customerType: formData.existing_customer ? "existing" : "new",
			distributor: formData.distributor || "",
			accountNumber: formData.existing_account_number || "",
			email: formData.account_information?.basic_details?.email || "",
			companyName:
				formData.account_information?.basic_details?.company_name || "",
			industry: formData.account_information?.basic_details?.industry || "",
			website: formData.account_information?.basic_details?.website || "",
			addressLine1:
				formData.account_information?.mailing_address?.address_line_1 || "",
			addressLine2:
				formData.account_information?.mailing_address?.address_line_2 || "",
			city: formData.account_information?.mailing_address?.city || "",
			state: formData.account_information?.mailing_address?.state || "",
			country: formData.account_information?.mailing_address?.country || "",
			zipCode: formData.account_information?.mailing_address?.zip_code || "",
		},
	});

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setError,
		clearErrors,
		setValue,
		trigger,
	} = methods;
	const customerType = watch("customerType");

	const accountNumber = watch("accountNumber");

	const [accountChecked, setAccountChecked] = React.useState(false);
	const [accountAvailable, setAccountAvailable] = React.useState(false);
	const [checkingAccount, setCheckingAccount] = React.useState(false);
	const [accountDetails, setAccountDetails] = React.useState({});

	useEffect(() => {
		setAccountChecked(false);
		setAccountAvailable(false);
		clearErrors("accountNumber");
	}, [accountNumber]);

	const fetchAccountDetails = async () => {
		setCheckingAccount(true);
		clearErrors("accountNumber");
		try {
			// Make an API call to fetch account details
			const response = await axios.get(
				`${BASE_API_URL}${API_ROUTES.CHECK_ACCOUNT}?accountNumber=${accountNumber}`
			);

			if (response.data?.data?.StatusCode === 200) {
				setAccountDetails(response.data?.data?.ConsumerData);
				setAccountAvailable(true);
				setAccountChecked(true);
			} else {
				setError("accountNumber", {
					type: "manual",
					message: "Account not found",
				});
			}
		} catch (error) {
			setError("accountNumber", {
				type: "manual",
				message: "Error checking account",
			});
		} finally {
			setCheckingAccount(false);
		}
	};

	useEffect(() => {
		if (accountAvailable) {
			if (accountDetails.Email) {
				setValue("email", accountDetails.Email);
			}
			if (accountDetails.CompanyName) {
				setValue("companyName", accountDetails.CompanyName);
			}
			if (accountDetails.Industry) {
				setValue("industry", accountDetails.Industry);
			}
			if (accountDetails.Website) {
				setValue("website", accountDetails.Website);
			}
			if (accountDetails.MailingStreet1) {
				setValue("addressLine1", accountDetails.MailingStreet1);
			}
			if (accountDetails.MailingStreet2) {
				setValue("addressLine2", accountDetails.MailingStreet2);
			}
			if (accountDetails.MailingCity) {
				setValue("city", accountDetails.MailingCity);
			}
			if (accountDetails.MailingState) {
				setValue("state", accountDetails.MailingState);
			}
			if (accountDetails.MailingCountry) {
				setValue("country", accountDetails.MailingCountry);
			}
			if (accountDetails.MailingZip) {
				setValue("zipCode", accountDetails.MailingZip.toString());
			}
			trigger();
		}
	}, [accountDetails, accountAvailable, setValue, trigger]);

	const onSubmit = (data) => {
		const info = {
			basic_details: {
				email: data.email,
				company_name: data.companyName,
				industry: data.industry,
				website: data.website,
			},
			mailing_address: {
				address_line_1: data.addressLine1,
				address_line_2: data.addressLine2,
				city: data.city,
				state: data.state,
				country: data.country,
				zip_code: data.zipCode,
			},
		};

		if (customerType === "existing") {
			formData.existing_account_number = accountNumber;
		}

		updateFormData({
			distributor: data.distributor,
			existing_customer: customerType === "existing" ? true : false,
			new_customer: customerType === "new" ? true : false,
			account_information: info,
		});
		setStep((prev) => prev + 1);
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<Stack spacing={8}>
					<Heading as="h1" size="lg" fontWeight="600">
						Account Information
					</Heading>

					<FormControl isDisabled={isReadOnly} isInvalid={!!errors.distributor}>
						<Controller
							name="distributor"
							control={control}
							render={({ field }) => (
								<Select {...field} placeholder="Select Distributor">
									<option value="direct">Direct</option>
									<option value="gts">GTS</option>
									<option value="clarus">Clarus</option>
									<option value="xaas1">Xaas1</option>
								</Select>
							)}
						/>
						<FormErrorMessage>{errors.distributor?.message}</FormErrorMessage>
					</FormControl>

					<Box>
						<Heading as="h2" fontWeight="600" size="md" fontSize="14px" mb={2}>
							Is this a new or existing customer?
						</Heading>
						<FormControl
							isDisabled={isReadOnly}
							isInvalid={!!errors.customerType}
						>
							<Controller
								name="customerType"
								control={control}
								render={({ field }) => (
									<RadioGroup {...field}>
										<Stack spacing={1}>
											<Radio value="existing" borderRadius="5px">
												Existing
											</Radio>
											<Radio value="new" borderRadius="5px">
												New
											</Radio>
										</Stack>
									</RadioGroup>
								)}
							/>

							<FormErrorMessage>
								{errors.customerType?.message}
							</FormErrorMessage>
						</FormControl>
					</Box>

					{customerType === "existing" && (
						<Flex gap={4}>
							<FormControl
								isDisabled={isReadOnly || checkingAccount}
								isInvalid={!!errors.accountNumber}
							>
								<Controller
									name="accountNumber"
									control={control}
									render={({ field }) => (
										<Input
											type="string"
											placeholder="Account Number"
											{...field}
										/>
									)}
								/>
								{accountAvailable && (
									<Text color="green.500">Account is Verified</Text>
								)}
								<FormErrorMessage>
									{errors.accountNumber?.message}
								</FormErrorMessage>
							</FormControl>
							{!isEnrollment && (
								<Button
									disabled={checkingAccount}
									isLoading={checkingAccount}
									borderRadius={"6px"}
									paddingX={"2rem"}
									onClick={fetchAccountDetails}
								>
									Check Account
								</Button>
							)}
						</Flex>
					)}
					{(customerType === "new" || accountAvailable || isEnrollment) && (
						<>
							<Box>
								<Heading
									as="h2"
									fontWeight="600"
									size="md"
									fontSize="14px"
									mb={2}
								>
									Contact Information
								</Heading>
								<Grid templateColumns="repeat(2, 1fr)" gap={4} fontSize="12px">
									<FormControl
										isDisabled={isReadOnly}
										isInvalid={!!errors.email}
									>
										<Controller
											name="email"
											control={control}
											render={({ field }) => (
												<Input
													type="email"
													placeholder="E-mail address"
													{...field}
												/>
											)}
										/>
										<FormErrorMessage>{errors.email?.message}</FormErrorMessage>
									</FormControl>

									<FormControl
										isDisabled={isReadOnly}
										isInvalid={!!errors.companyName}
									>
										<Controller
											name="companyName"
											control={control}
											render={({ field }) => (
												<Input placeholder="Company Name" {...field} />
											)}
										/>
										<FormErrorMessage>
											{errors.companyName?.message}
										</FormErrorMessage>
									</FormControl>

									<FormControl
										isDisabled={isReadOnly}
										isInvalid={!!errors.industry}
									>
										<Controller
											name="industry"
											control={control}
											render={({ field }) => (
												<Input placeholder="Industry" {...field} />
											)}
										/>
										<FormErrorMessage>
											{errors.industry?.message}
										</FormErrorMessage>
									</FormControl>

									<FormControl
										isDisabled={isReadOnly}
										isInvalid={!!errors.website}
									>
										<Controller
											name="website"
											control={control}
											render={({ field }) => (
												<Input placeholder="Website" {...field} />
											)}
										/>
										<FormErrorMessage>
											{errors.website?.message}
										</FormErrorMessage>
									</FormControl>
								</Grid>
							</Box>

							<Box>
								<Heading
									as="h2"
									fontWeight="600"
									size="md"
									fontSize="14px"
									mb={2}
								>
									Address
								</Heading>
								<Grid templateColumns="repeat(2, 1fr)" gap={4} fontSize="12px">
									<FormControl
										isDisabled={isReadOnly}
										isInvalid={!!errors.addressLine1}
									>
										<Controller
											name="addressLine1"
											control={control}
											render={({ field }) => (
												<Input placeholder="Address Line 1" {...field} />
											)}
										/>
										<FormErrorMessage>
											{errors.addressLine1?.message}
										</FormErrorMessage>
									</FormControl>

									<FormControl isDisabled={isReadOnly}>
										<Controller
											name="addressLine2"
											control={control}
											render={({ field }) => (
												<Input
													placeholder="Address Line 2 (optional)"
													{...field}
												/>
											)}
										/>
									</FormControl>

									<LocationSelector
										isReadOnly={isReadOnly}
										defaultCountry={
											accountDetails.MailingCountry ||
											formData.account_information?.mailing_address?.country ||
											""
										}
										defaultState={
											accountDetails.MailingState ||
											formData.account_information?.mailing_address?.state ||
											""
										}
										defaultCity={
											accountDetails.MailingCity ||
											formData.account_information?.mailing_address?.city ||
											""
										}
									/>

									<FormControl
										isDisabled={isReadOnly}
										isInvalid={!!errors.zipCode}
									>
										<Controller
											name="zipCode"
											control={control}
											render={({ field }) => (
												<Input
													placeholder="Zip Code"
													value={field.value || ""}
													onChange={(e) => field.onChange(e.target.value)}
													onBlur={field.onBlur}
												/>
											)}
										/>
										<FormErrorMessage>
											{errors.zipCode?.message}
										</FormErrorMessage>
									</FormControl>
								</Grid>
							</Box>
						</>
					)}
					<NavigationButtons
						step={step}
						setStep={setStep}
						finalStep={finalStep}
						onSubmit={() => {
							if (!isEnrollment && customerType === "existing") {
								if (!accountNumber) {
									setError("accountNumber", {
										type: "manual",
										message: "Account number is required",
									});
									return;
								} else if (!accountChecked) {
									setError("accountNumber", {
										type: "manual",
										message:
											"Please check the account number before proceeding",
									});
									return;
								} else {
									handleSubmit(onSubmit)();
								}
							} else {
								handleSubmit(onSubmit)();
							}
						}}
					/>
				</Stack>
			</form>
		</FormProvider>
	);
};

export default AccountInformation;
