import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { createOrderService } from "services/apiOnboard";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export const useCreateOrder = () => {
	const history = useHistory();
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const { isLoading, mutate: createOrder } = useMutation({
		mutationKey: ["createOrder"],
		mutationFn: async (orderData) => {
			console.log(orderData);
			// console.log(orderData);
			return createOrderService(orderData);
		},
		onSuccess: (res) => {
			try {
				const response = res.data;
				if (response && res.status !== 202) {
					if (res?.status === 401) {
						localStorage.removeItem("Titan_Access");
						history.replace("/auth/sign-in");
						toast.error("Unauthorized access. Please login again.");
						setIsError(true);
					} else {
						toast.error(res?.data?.message || "Internal server error.");
						setIsError(true);
					}
				} else {
					toast.success("Order created successfully");
					setIsSuccess(true);
				}
			} catch (err) {
				toast.error("Error Occured : Data not fetched");
				setIsError(true);
			}
		},
		onError: () => {
			toast.error("Failed to create order");
			setIsError(true);
		},
	});

	return {
		isLoading,
		createOrder,
		isSuccess,
		isError,
	};
};
