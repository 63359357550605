import { useMutation } from "react-query";
import { verifyUserService } from "services/auth.service";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";

export const useVerify = () => {
	const history = useHistory();

	const {
		isLoading,
		mutate: verify,
		data,
	} = useMutation({
		mutationKey: ["verify"],
		mutationFn: (verifyObj) => {
			if (!verifyObj.email || !verifyObj.token) {
				history.replace("/auth/sign-in");
				toast.error("Invalid Magic Link, Please try again !");
				return;
			} else {
				return verifyUserService(verifyObj);
			}
		},
		onSuccess: (response) => {
			if (response) {
				if (response.success === true) {
					localStorage.setItem("Titan_Access", response.data?.AccessToken);
					history.push("/admin");
					toast.success(response.message);
				} else {
					history.replace("/auth/sign-in");
					toast.error("Invalid Magic Link, Please try again !");
				}
			}
		},
	});
	return { isLoading, verify, userData: data };
};
