import React from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  Flex,
  Icon,
  Input,
  Text,
} from "@chakra-ui/react";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";

const PortForwardingModal = ({
  isOpen,
  onClose,
  rules,
  handleRuleChange,
  handleDeleteRule,
  handleAddRule,
}) => {
  const handleBackToDashboard = () => {
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement="right" >
      <DrawerOverlay />
      <DrawerContent maxWidth="700px" borderRadius="md" p={8} m={8}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="2xl" fontWeight="bold">
            Port Forwarding Rules
          </Text>
          <Box
            as="button"
            onClick={onClose}
            p={2}
            borderRadius="md"
            _hover={{ bg: "gray.100" }}
          >
            <Icon as={CloseIcon} w={6} h={6} />
          </Box>
        </Flex>
        <Text mb={4} fontSize="sm">
          Here you can find all the port forwarding rules for the Virtual
          Network
        </Text>
        <DrawerBody px={0}>
          <Flex
            fontWeight="bold"
            fontSize="sm"
            justifyContent="space-between"
            mb={2}
            width="94%"
          >
            <Text width="full" textAlign="left">
              Source Address
            </Text>
            <Text width="full" textAlign="left">
              Source Port
            </Text>
            <Text width="full" textAlign="left">
              Dest. Address
            </Text>
            <Text width="full" textAlign="left">
              Destination Port
            </Text>
            <Text width="auto" />
          </Flex>

          {rules.map((rule, index) => (
            <Box key={index} mb={4}>
              <Flex mb={4} gap={4} alignItems="center" width="100%">
                <Input
                  value={rule.sourceAddress}
                  onChange={(e) =>
                    handleRuleChange(index, "sourceAddress", e.target.value)
                  }
                  placeholder="Source Address"
                  bg="white"
                  borderRadius={5}
                  width="full"
                />
                <Input
                  value={rule.sourcePort}
                  onChange={(e) =>
                    handleRuleChange(index, "sourcePort", e.target.value)
                  }
                  placeholder="Source Port"
                  bg="white"
                  borderRadius={5}
                  width="full"
                />
                <Input
                  value={rule.destAddress}
                  onChange={(e) =>
                    handleRuleChange(index, "destAddress", e.target.value)
                  }
                  placeholder="Destination Address"
                  bg="white"
                  borderRadius={5}
                  width="full"
                />
                <Input
                  value={rule.destPort}
                  onChange={(e) =>
                    handleRuleChange(index, "destPort", e.target.value)
                  }
                  placeholder="Destination Port"
                  bg="white"
                  borderRadius={5}
                  width="full"
                />
                <Box
                  bg="white"
                  borderRadius={5}
                  p={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => handleDeleteRule(index)}
                >
                  <Icon as={TrashIcon} w={5} h={5} />
                </Box>
              </Flex>
            </Box>
          ))}
        </DrawerBody>

        <DrawerFooter px={0}>
          <Button
            bg="#EDEBFF"
            color="#422AFB"
            _hover={{ bg: "#EDEBFF" }}
            _active={{ bg: "#d2c7ff" }}
            variant="solid"
            mr={2}
            borderRadius={8}
            fontSize="sm"
            width="full"
            onClick={handleAddRule}
          >
            Add New Rule
          </Button>
          <Button
            bg="#422AFB"
            color="white"
            border="1px solid #5c18e1"
            _hover={{ bg: "#422AFB" }}
            _active={{ bg: "#d2c7ff" }}
            variant="outline"
            borderRadius={8}
            fontSize="sm"
            width="full"
            onClick={handleBackToDashboard}
          >
            Back to Dashboard
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default PortForwardingModal;
