import axios from "axios";
import { API_ROUTES } from "constants/api.constants";
import { BASE_API_URL } from "constants/api.constants";
import removeNullValuesAndFormat from "utils/removeNullAndFormat";

export async function getVmService(filterObj) {
  try {
    let url = BASE_API_URL + API_ROUTES.GET_VIRTUAL_MACHINES;
    let params = removeNullValuesAndFormat(filterObj);
    if (params.length) {
      url += "?" + params.join("&");
    }
    
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });
    
    return response;
  } catch (error) {
    if (!error.response) {
      throw new Error("Network error occurred");
    }
    return error.response; 
  }
}

export async function getFirewallsService(reqObj) {
    try {
        let url = BASE_API_URL + API_ROUTES.GET_FIREWALLS;
        let params = removeNullValuesAndFormat(reqObj);
        if (params.length) {
            url += "?" + params.join("&");
        }
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
            },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function getVirtualNetworkService(reqObj) {
	try {
	  let url = BASE_API_URL + API_ROUTES.GET_VIRTUAL_NETWORKS;
	  let params = removeNullValuesAndFormat(reqObj);
  
	  if (params.length) {
		url += "?" + params.join("&");
	  }
  
	  const response = await axios.get(url, {
		headers: {
		  Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
		},
	  });
  
	  return response;
	} catch (error) {
	  return error.response;
	}
  }

  export const createVMService = async (payload) => {
	try {
	  const response = await axios.post(API_ROUTES.CREATE_VIRTUAL_MACHINE, payload, {
		headers: {
		   Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
		  "Content-Type": "application/json",
		},
	  });
	  return response.data;
	} catch (error) {
	  throw new Error(error.response?.data?.message || "Failed to create VM");
	}
  };