import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { getVirtualNetworkService } from "services/apiVm";
import { useHistory } from "react-router-dom";

export const useGetVirtualNetworks = (filterObject) => {
  const history = useHistory();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["virtual-networks", filterObject],
    queryFn: () => getVirtualNetworkService(filterObject),
    onError: (error) => {
      history.replace("/auth/sign-in");
      toast.error("Failed to fetch virtual networks.");
    },
    onSuccess: (res) => {
      try {
        const response = res.data;
        if (response && res.status !== 200) {
          if (res.status === 401) {
            localStorage.removeItem("Titan_Access");
            history.replace("/auth/sign-in");
            toast.error("Unauthorized access. Please login again.");
          } else if(res.status === 500){
            toast.error( "Internal server error.");
          }
        }
      } catch (err) {
        toast.error("Error Occurred: Data not fetched");
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  
  return {
    virtualNetwork: data?.data?.data?.vnet|| null,
    isLoading,
    refetch,
  };
};
