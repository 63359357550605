// DashboardTable.js
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text, Center } from "@chakra-ui/react";
import { TableRow } from "./TableRow";

export const DashBoardTable = ({ heads, data, onRowClick }) => {
	return (
		<TableContainer borderRadius={5} width="100%" border="1px solid #DFE5ED">
			<Table variant="simple">
				<Thead bgColor="#35455B">
					<Tr>
						{heads.map((key) => (
							<Th
								key={key}
								color="white"
								fontWeight="medium"
								textTransform="none"
								fontSize="sm"
								border="1px solid #DFE5ED"
							>
								<Center>{key}</Center>
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody bgColor="white">
					{data?.length === 0 ? (
						<Tr>
							<Td colSpan={heads.length} border="1px solid #DFE5ED">
								<Text textAlign="center" fontStyle="italic">
									--nothing to show--
								</Text>
							</Td>
						</Tr>
					) : (
						data.map((row, index) => (
							<TableRow key={index} data={row} onRowClick={onRowClick} />
						))
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
};