import { Button, ButtonGroup } from "@chakra-ui/react";
import React from "react";

const NavigationButtons = ({
	step,
	setStep,
	finalStep,
	isSubmitting,
	onSubmit,
}) => {
	return (
		<ButtonGroup
			width="100%"
			mt={4}
			display="flex"
			justifyContent="space-between"
		>
			{step > 1 && (
				<Button
					type="button"
					backgroundColor={"#C6C6C9"}
					variant="outline"
					fontSize="sm"
					onClick={() => setStep((prevStep) => prevStep - 1)}
					flex="1"
					maxWidth="33%"
				>
					Back
				</Button>
			)}

			<Button
				type="button"
				colorScheme="blue"
				fontSize="md"
				isLoading={isSubmitting}
				onClick={onSubmit}
				flex="2"
			>
				{step === finalStep ? "Submit" : "Next"}
			</Button>
		</ButtonGroup>
	);
};

export default NavigationButtons;
