import React, { useState } from "react";
import { Box, Input, Checkbox, Flex, Text ,Select, Icon} from "@chakra-ui/react";
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/dropdown.svg'

const NetworkConfiguration = ({ formData, updateFormData, handleNextClick }) => {
  const [form, setForm] = useState({
    ipv4Address: formData.ipv4Address || '',
    ipv6Address: formData.ipv6Address || '',
    useDHCP: formData.useDHCP || false,
    enableGateway: formData.enableGateway || false,
    gatewayIP: formData.gatewayIP || '',
    gatewaySubnet: formData.gatewaySubnet || '',
    bandwidth: formData.bandwidth || '',
    addFirewall: formData.addFirewall || false,
    addVPN: formData.addVPN || false,
  });

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <Box py={5} width="110%">
      <Box >
        <Text fontSize="md" fontWeight="bold" mb={4}>CIDR</Text>
        <Select placeholder="Region Selection" mb={6} bg="white" icon={<Icon as={DropdownIcon} />} />
        <Flex gap={2} mb={4}>
          <Input
            placeholder="IPv4 Address *"
            name="ipv4Address"
            value={form.ipv4Address}
            onChange={handleInputChange}
            backgroundColor="white"
            required
          />
          <Input
            placeholder="IPv6 Address (Optional)"
            name="ipv6Address"
            value={form.ipv6Address}
            onChange={handleInputChange}
            backgroundColor="white"
          />
        </Flex>

        <Checkbox
          name="useDHCP"
          isChecked={form.useDHCP}
          onChange={handleInputChange}
          mb={4}
          mr={20}
        >
          Use DHCP
        </Checkbox>

        {form.useDHCP && (
          <Flex gap={2} mb={4}>
            <Input
              placeholder="DHCP Start IP"
              name="dhcpStartIP"
              value={form.dhcpStartIP}
              onChange={handleInputChange}
              backgroundColor="white"
            />
            <Input
              placeholder="DHCP End IP"
              name="dhcpEndIP"
              value={form.dhcpEndIP}
              onChange={handleInputChange}
              backgroundColor="white"
            />
          </Flex>
        )}

        <Checkbox
          name="enableGateway"
          isChecked={form.enableGateway}
          onChange={handleInputChange}
          mb={4}
        >
          Enable Gateway
        </Checkbox>

        {form.enableGateway && (
          <Flex gap={2} mb={4}>
            <Input
              placeholder="Gateway IP"
              name="gatewayIP"
              value={form.gatewayIP}
              onChange={handleInputChange}
              backgroundColor="white"
            />
            <Input
              placeholder="Gateway Subnet"
              name="gatewaySubnet"
              value={form.gatewaySubnet}
              onChange={handleInputChange}
              backgroundColor="white"
            />
          </Flex>
        )}

        <Text fontSize="md" fontWeight="bold" my={4}>Miscellaneous</Text>
        <Input
          placeholder="Bandwidth"
          name="bandwidth"
          value={form.bandwidth}
          onChange={handleInputChange}
          backgroundColor="white"
          mb={4}
        />

        <Flex gap={4} mb={4} width="65%" justifyContent="space-between">
          <Checkbox
            name="addFirewall"
            isChecked={form.addFirewall}
            onChange={handleInputChange}
          >
            Add Firewall
          </Checkbox>

          <Checkbox
            name="addVPN"
            isChecked={form.addVPN}
            onChange={handleInputChange}
          >
            Add VPN
          </Checkbox>
        </Flex>
      </Box>
    </Box>
  );
};

export default NetworkConfiguration;