import axios from "axios";

export async function createLeadService(lead) {
	try {
		const url = "https://api.titancloudstorage.com/v2/Lead";
		const response = await axios.post(url, lead, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
			},
		});
		return response.data;
	} catch (error) {
		return error.response;
	}
}
