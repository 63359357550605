const { Box, Flex, Circle, Text } = require("@chakra-ui/react");

const StepTracker = ({ stepForm, step }) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      mb={6}
      ml={2}
      width={step === 1 ? "59%" : "93%"}
      position="relative"
    >
      <Flex alignItems="center" justifyContent="space-between" px={stepForm.length===4?"4rem":"5.5rem"}>
        {stepForm.map((item, index) => (
          <Flex key={index} alignItems="center" flex={index === stepForm.length - 1 ? '0 0 auto' : '1'}>
            <Circle
              color="white"
              bgColor={index < step ? "#422AFB" : "gray.300"}
              opacity={index < step ? 1 : 0.5}
              size="8"
            >
              {index + 1}
            </Circle>
            
            {index < stepForm.length - 1 && (
              <Box
                flex="1"
                height="2px"
                bgColor={index < step - 1 ? "#422AFB" : "gray.300"}
                opacity={index < step - 1 ? 1 : 0.5}
              />
            )}
          </Flex>
        ))}
      </Flex>

      {/* Step Labels */}
      <Flex width="full" mt={2} justifyContent="space-between">
        {stepForm.map((item, index) => (
          <Box key={index} textAlign="center" flex="1">
            <Text
              fontSize="sm"
              fontWeight="bold"
              color={index < step ? "black" : "gray.300"}
            >
              Step {item.step}
            </Text>
            <Text
              fontSize="sm"
              color={index < step ? "black" : "gray.600"}
              opacity={index < step ? 1 : 0.5}
            >
              {item.name}
            </Text>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default StepTracker;