import React, { useState, useEffect } from "react";
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "routes.js";
import ProtectedRoute from "features/Authentication/ProtectedRoute";

export default function Dashboard(props) {
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [routeText, setRouteText] = useState("TitanCloud");
  const [showNavbar, setShowNavbar] = useState(true);
  const location = useLocation();

  const toggleCreate = () => setIsCreate((prev) => !prev);
  const toggleNavbar = (visible) => setShowNavbar(visible);

  const getActiveRoute = (routes) => {
    let activeRoute = "TitanCloud";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRouteText = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseRouteText = getRouteText(routes[i].items);
        if (collapseRouteText) return collapseRouteText;
      } else if (routes[i].category) {
        let categoryRouteText = getRouteText(routes[i].items);
        if (categoryRouteText) return categoryRouteText;
      } else if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        if (["Virtual Machines", "Virtual Networks", "Firewalls"].includes(routes[i].name)) {
          return `Dashboard → Management → Compute → ${routes[i].name}`;
        } else {
          return null;
        }
      }
    }
    return "TitanCloud";
  };

  useEffect(() => {
    setRouteText(getRouteText(routes));
  }, [location.pathname]);
  
  useEffect(() => {
    setIsCreate(false);
    setShowNavbar(true)
  }, [location.pathname]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={(props) => (
              <prop.component
                {...props}
                isCreate={isCreate}
                toggleCreate={toggleCreate}
                toggleNavbar={toggleNavbar}
              />
            )}
          />
        );
      }
      if (prop.collapse || prop.category) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveRouteDescription = (routes) => {
    let activeDescription = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveDescription = getActiveRouteDescription(
          routes[i].items
        );
        if (collapseActiveDescription !== activeDescription) {
          return collapseActiveDescription;
        }
      } else if (routes[i].category) {
        let categoryActiveDescription = getActiveRouteDescription(
          routes[i].items
        );
        if (categoryActiveDescription !== activeDescription) {
          return categoryActiveDescription;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].description || "";
        }
      }
    }
    return activeDescription;
  };
  const { onOpen } = useDisclosure();

  return (
    <ProtectedRoute>
    <Box>
      <Box>
        <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            px={5}
            pt={3}
            w={{ base: "100%", xl: "calc(100% - 290px)" }}
            maxWidth={{ base: "100%", xl: "calc(100% - 290px)" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          >
            <Portal>
              <Box>
                { !isCreate && showNavbar && (
                  <Navbar
                    onOpen={onOpen}
                    logoText={"Titan"}
                    routeText={routeText}
                    brandText={getActiveRoute(routes)}
                    secondary={getActiveNavbar(routes)}
                    description={getActiveRouteDescription(routes)}
                    message={getActiveNavbarText(routes)}
                    fixed={fixed}
                    {...rest}
                  />
                )}
              </Box>
            </Portal>

            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to="/admin/orders" />
              </Switch>
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
    </ProtectedRoute>
  );
}