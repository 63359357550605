import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { uploadFileService } from "services/apiUpload";
import { createEnrollmentService } from "services/apiOnboard";

export const useCreateEnrollment = () => {
	const location = useLocation();
	const history = useHistory();
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);

	// Extract email and token from URL query parameters
	const queryParams = new URLSearchParams(location.search);
	const email = queryParams.get("email");
	const token = queryParams.get("token");

	// Enrollment creation hook
	const { isLoading, mutate: createEnrollment } = useMutation({
		mutationKey: ["createEnrollment"],
		mutationFn: async ({ enrollmentData }) => {
			console.log(enrollmentData);
			if (!email || !token) {
				throw new Error("Invalid link");
			}

			// // Upload the file using the service
			// const responseData = await uploadFileService(file);

			// if (responseData?.status !== 200) {
			// 	throw new Error("Failed to upload file");
			// }

			// enrollmentData.customer_agreement.customer_signature =
			// 	responseData?.data?.data;

			// Proceed with enrollment creation if file upload is successful
			return createEnrollmentService(enrollmentData, email, token);
		},
		onSuccess: (res) => {
			const response = res.data;
			if (response && res.status !== 202) {
				if (res?.status === 401) {
					if (res?.data?.message === "token not found") {
						setIsError(true);
						toast.error("Invalid link");
					} else {
						history.replace("/auth/sign-in");
						toast.error("Unauthorized access. Please login again.");
					}
				} else {
					setIsError(true);
					toast.error(res?.data?.message || "Internal server error.");
				}
			} else {
				toast.success("Enrollment created successfully");
				setIsSuccess(true);
			}
		},
		onError: () => {
			toast.error("Failed to create enrollment");
			setIsError(true);
		},
	});

	return {
		isLoading,
		createEnrollment,
		isSuccess,
		isError,
	};
};
