import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { getOrdersService } from "services/apiOrders";
import { useHistory } from "react-router-dom";

export const useGetOrders = (filterObject) => {
	const history = useHistory();
	const { data, isLoading, refetch } = useQuery({
		queryKey: ["orders"],
		queryFn: () => getOrdersService(filterObject),
		onError: (error) => {
			history.replace("/auth/sign-in");
			toast.error("Failed to fetch orders.");
		},
		onSuccess: (res) => {
			try {
				const response = res.data;
				if (response && res.status !== 202) {
					if (res.status === 401) {
						localStorage.removeItem("Titan_Access");
						history.replace("/auth/sign-in");
						toast.error("Unauthorized access. Please login again.");
					} else {
						toast.error(res?.data?.message || "Internal server error.");
					}
				}
			} catch (err) {
				toast.error("Error Occured : Data not fetched");
			}
		},
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	return {
		orders: data?.data?.data?.orders || [],
		results: data?.data?.data?.results || 0,
		isLoading,
		refetch,
	};
};
