import React, { useState } from "react";
import OrderACS from "./forms/order/OrderACS";
import OrderDatabase from "./forms/order/OrderDatabase";
import OrderVM from "./forms/order/OrderVM";

export default function CreateOrderForm({ selectedOption, setCreated }) {
  const [step, setStep] = useState(Number(sessionStorage.getItem("step")) || 1);
  const [formData, setFormData] = useState(
    JSON.parse(sessionStorage.getItem("formData")) || {}
  );

  const handleSubmit = () => {
    sessionStorage.removeItem("formData");
    sessionStorage.removeItem("step");
  };

  const updateFormData = (updatedData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  const renderForm = () => {
    switch (selectedOption) {
      case "ACS":
        return (
          <OrderACS
            step={step}
            setStep={setStep}
            formData={formData}
            setCreated={setCreated}
            updateFormData={updateFormData}
            finalStep={3}
            orderType="ACS"
          />
        );
      case "Virtual Machine":
        return (
          <OrderVM
            step={step}
            setStep={setStep}
            formData={formData}
            setCreated={setCreated}
            finalStep={4}
            orderType="VM"
          />
        );
      case "Relational Database":
        return (
          <OrderDatabase
            step={step}
            setStep={setStep}
            formData={formData}
            setCreated={setCreated}
            orderType="Database"
          />
        );
      default:
        return null;
    }
  };

  return <>{renderForm()}</>;
}