/* eslint-disable */

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import toast from "react-hot-toast";
import { loginUser } from "services/auth.service";

function SignIn() {
	// Chakra color mode
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = "gray.400";
	const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
	const textColorBrand = useColorModeValue("brand.500", "white");
	const brandStars = useColorModeValue("brand.500", "brand.400");
	const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
	const googleText = useColorModeValue("navy.700", "white");
	const googleHover = useColorModeValue(
		{ bg: "gray.200" },
		{ bg: "whiteAlpha.300" }
	);
	const googleActive = useColorModeValue(
		{ bg: "secondaryGray.300" },
		{ bg: "whiteAlpha.200" }
	);
	const [show, setShow] = React.useState(false);
	const [email, setEmail] = useState();
	const handleClick = () => setShow(!show);

	const handleLogin = async () => {
		try {
			console.log(email);
			if (!email?.length) {
				console.log("Please enter email");
				toast.error("Please enter email");
				return;
			}

			if (!email.includes("@")) {
				console.log("Please enter valid email");
				toast.error("Please enter valid email");
				return;
			}

			const response = await loginUser({ email });
			const { success, message } = response;
			if (success) {
				console.log("Login success");
				toast.success(message);
			} else {
				console.log("Login failed");
				toast.error(message);
			}
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}
	};

	return (
		<DefaultAuth illustrationBackground={illustration} image={illustration}>
			<Flex
				maxW={{ base: "100%", md: "max-content" }}
				w="100%"
				mx={{ base: "auto", lg: "0px" }}
				me="auto"
				h="100%"
				alignItems="start"
				justifyContent="center"
				mb={{ base: "30px", md: "60px" }}
				px={{ base: "25px", md: "0px" }}
				mt={{ base: "40px", md: "26vh" }}
				flexDirection="column"
			>
				<Box me="auto">
					<Heading color={textColor} fontSize="36px" mb="10px">
						Sign In
					</Heading>
					<Text
						mb="36px"
						ms="4px"
						color={textColorSecondary}
						fontWeight="400"
						fontSize="md"
					>
						Enter your email to login via email link!
					</Text>
				</Box>
				<Flex
					zIndex="2"
					direction="column"
					w={{ base: "100%", md: "420px" }}
					maxW="100%"
					background="transparent"
					borderRadius="15px"
					mx={{ base: "auto", lg: "unset" }}
					me="auto"
					mb={{ base: "20px", md: "auto" }}
				>
					<FormControl>
						<FormLabel
							display="flex"
							ms="4px"
							fontSize="sm"
							fontWeight="500"
							color={textColor}
							mb="8px"
						>
							Email<Text color={brandStars}>*</Text>
						</FormLabel>
						<Input
							isRequired={true}
							variant="auth"
							fontSize="sm"
							ms={{ base: "0px", md: "0px" }}
							type="email"
							placeholder="mail@titancloud.com"
							mb="24px"
							fontWeight="500"
							size="lg"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						{/* <Flex justifyContent="space-between" align="center" mb="24px">
							<FormControl display="flex" alignItems="center">
								<Checkbox
									id="remember-login"
									colorScheme="brandScheme"
									me="10px"
								/>
								<FormLabel
									htmlFor="remember-login"
									mb="0"
									fontWeight="normal"
									color={textColor}
									fontSize="sm"
								>
									Keep me logged in
								</FormLabel>
							</FormControl>
						</Flex> */}
						{/* <NavLink to='/admin/leads'> */}
						<Button
							fontSize="sm"
							variant="brand"
							fontWeight="500"
							w="100%"
							h="50"
							onClick={handleLogin}
							mb="24px"
						>
							Sign In
						</Button>
						{/* </NavLink> */}
					</FormControl>
				</Flex>
			</Flex>
		</DefaultAuth>
	);
}

export default SignIn;
