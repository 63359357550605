import axios from "axios";
import { API_ROUTES, BASE_API_URL } from "constants/api.constants";
import removeNullValuesAndFormat from "utils/removeNullAndFormat";

export async function getOrderQuote(reqObj) {
	try {
		let url = `${BASE_API_URL}${API_ROUTES.GET_ORDERQUOTE}`;

		let params = removeNullValuesAndFormat(reqObj);

		if (params.length) {
			url += "?" + params.join("&");
		}
		const response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
			},
		});

		return response;
	} catch (error) {
		return error.response;
	}
}
