import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Text,
  Select,
  Input,
  VStack,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { ReactComponent as DropdownIco } from "../../../../assets/icons/dropdown2.svg";
import PortForwardingModal from "../components/PortForwardingModal";
import UserManagement from "./userManagement/UserManagement";

export default function VnDetails({ data = {}, onBack = () => {} }) {
  const [isUserManagement, setIsUserManagement] = useState(false);
  const [dhcpEnabled, setDhcpEnabled] = useState("Yes");
  const [gatewayEnabled, setGatewayEnabled] = useState("Yes");
  const [vpnEnabled, setVpnEnabled] = useState("Yes");
  const [vpnType, setVpnType] = useState("OpenVPN");
  const [isCreate, setIsCreate] = useState(false);
  const [rules, setRules] = useState([
    { sourceAddress: "", sourcePort: "", destAddress: "", destPort: "" },
    { sourceAddress: "", sourcePort: "", destAddress: "", destPort: "" },
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRuleChange = (index, field, value) => {
    const updatedRules = [...rules];
    updatedRules[index][field] = value;
    setRules(updatedRules);
  };

  const handleAddRule = () => {
    setRules([
      ...rules,
      { sourceAddress: "", sourcePort: "", destAddress: "", destPort: "" },
    ]);
  };

  const handleDeleteRule = (index) => {
    const updatedRules = rules.filter((_, i) => i !== index);
    setRules(updatedRules);
  };

  const handleUserManagementClick = () => {
    setIsUserManagement(true);
  };

  const handleBackClick = () => {
    if (isCreate) {
      setIsCreate(false);
    } else if (isUserManagement) {
      setIsUserManagement(false);
    } else {
      onBack();
    }
  };

  return (
    <Box>
      <Box position="absolute" top="3rem">
        <Link py={1} display="flex">
          {"Dashboard → Management → Compute →"}
          {isUserManagement ? (
            <Text fontWeight="bold">
              Virtual Network → Account {data.accountNumber} →{" "}
              {isCreate ? "Add VPN User" : "User Management VPN"}
            </Text>
          ) : (
            <Text fontWeight="bold">
              Virtual Network → Account {data.accountNumber}
            </Text>
          )}
        </Link>
        <Box display="flex" alignItems="center">
          <Link
            href="#"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={handleBackClick}
          >
            <Box
              backgroundColor="#363853"
              borderRadius="50%"
              p={1.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={MdArrowBack} color="white" w={5} h={5} />
            </Box>
          </Link>
          <Text fontWeight="bold" fontSize="35px" ml={3} color="#363853">
          {isUserManagement
              ? isCreate
                ? "Add VPN User"
                : "User Management - VPNs"
              : "Management - Virtual Networks"}
          </Text>
        </Box>
      </Box>

      {isUserManagement ? (
        <UserManagement isCreate={isCreate} setIsCreate={setIsCreate} />
      ) : (
        <>
          <Flex justifyContent="space-between" py={4}>
            <Box width="48%">
              <VStack align="stretch" spacing={6}>
                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                    Information
                  </Text>
                  <VStack align="stretch" spacing={2}>
                    <Flex justifyContent="space-between">
                      <Text>V Net ID:</Text>
                      <Text>{data.vNetId}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text>Region:</Text>
                      <Text>{data.region}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text>IPv4 address:</Text>
                      <Flex alignItems="center" gap={3}>
                        <Box bg="white" px={7} py={0.5} borderRadius={2}>
                          {data.dhcpIpStart}
                        </Box>
                        <Text>to</Text>
                        <Box bg="white" px={7} py={0.5} borderRadius={2}>
                          {data.dhcpIpEnd}
                        </Box>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text>IPv6 address:</Text>
                      <Flex alignItems="center" gap={3}>
                        <Box bg="white" px={7} py={0.5} borderRadius={2}>
                          {data.dhcpIpStart}
                        </Box>
                        <Text>to</Text>
                        <Box bg="white" px={7} py={0.5} borderRadius={2}>
                          {data.dhcpIpEnd}
                        </Box>
                      </Flex>
                    </Flex>
                  </VStack>
                </Box>

                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                    DHCP
                  </Text>
                  <VStack align="stretch" spacing={2}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text>Enabled:</Text>
                      <Select
                        value={dhcpEnabled}
                        onChange={(e) => setDhcpEnabled(e.target.value)}
                        width="auto"
                        border="none"
                        focusBorderColor="transparent"
                        icon={<DropdownIco />}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Select>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text>IPv4 address:</Text>
                      <Flex alignItems="center" gap={3}>
                        <Box bg="white" px={7} py={0.5} borderRadius={2}>
                          {data.dhcpIpStart}
                        </Box>
                        <Text>to</Text>
                        <Box bg="white" px={7} py={0.5} borderRadius={2}>
                          {data.dhcpIpEnd}
                        </Box>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text>IPv6 address:</Text>
                      <Flex alignItems="center" gap={3}>
                        <Box bg="white" px={7} py={0.5} borderRadius={2}>
                          {data.dhcpIpStart}
                        </Box>
                        <Text>to</Text>
                        <Box bg="white" px={7} py={0.5} borderRadius={2}>
                          {data.dhcpIpEnd}
                        </Box>
                      </Flex>
                    </Flex>
                  </VStack>
                </Box>

                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                    Gateway
                  </Text>
                  <VStack align="stretch" spacing={2}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text>Enabled:</Text>
                      <Select
                        value={gatewayEnabled}
                        onChange={(e) => setGatewayEnabled(e.target.value)}
                        width="auto"
                        border="none"
                        focusBorderColor="transparent"
                        icon={<DropdownIco />}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Select>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text>Gateway IP:</Text>
                      <Text bg="white" px={7} py={0.5} borderRadius={2}>
                        {data.dhcpIpEnd}
                      </Text>
                    </Flex>
                  </VStack>
                </Box>
              </VStack>
            </Box>

            <Box width="48%">
              <VStack align="stretch" spacing={6}>
                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                    Port Forwarding
                  </Text>
                  <VStack align="stretch" spacing={3}>
                    <Flex justifyContent="space-between" gap={3}>
                      <Input
                        placeholder="Source Address"
                        bg="white"
                        _placeholder={{ color: "black" }}
                        height={7}
                        textAlign="center"
                      />
                      <Input
                        placeholder="Source Port"
                        bg="white"
                        _placeholder={{ color: "black" }}
                        height={7}
                        textAlign="center"
                      />
                    </Flex>
                    <Flex justifyContent="space-between" gap={3}>
                      <Input
                        placeholder="Destination Address"
                        bg="white"
                        _placeholder={{ color: "black" }}
                        height={7}
                        textAlign="center"
                      />
                      <Input
                        placeholder="Destination Port"
                        bg="white"
                        _placeholder={{ color: "black" }}
                        height={7}
                        textAlign="center"
                      />
                    </Flex>
                    <HStack justifyContent="space-between" width="100%">
                      <Button
                        bg="#EDEBFF"
                        color="#422AFB"
                        _hover={{ bg: "#EDEBFF" }}
                        _active={{ bg: "#d2c7ff" }}
                        variant="solid"
                        mr={2}
                        borderRadius={8}
                        width="full"
                        fontSize="sm"
                      >
                        Add New Rule
                      </Button>
                      <Button
                        bg="white"
                        color="#422AFB"
                        border="1px solid #5c18e1"
                        _hover={{ bg: "#EDEBFF" }}
                        _active={{ bg: "#e1d5ff" }}
                        variant="outline"
                        borderRadius={8}
                        width="full"
                        fontSize="sm"
                        onClick={onOpen}
                      >
                        View All Rules
                      </Button>
                      <PortForwardingModal
                        isOpen={isOpen}
                        onClose={onClose}
                        rules={rules}
                        handleRuleChange={handleRuleChange}
                        handleDeleteRule={handleDeleteRule}
                        handleAddRule={handleAddRule}
                      />
                    </HStack>
                  </VStack>
                </Box>

                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                    VPN
                  </Text>
                  <VStack align="stretch" spacing={2}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text>Enabled:</Text>
                      <Select
                        value={vpnEnabled}
                        onChange={(e) => setVpnEnabled(e.target.value)}
                        width="auto"
                        border="none"
                        focusBorderColor="transparent"
                        icon={<DropdownIco />}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Select>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text>Type:</Text>
                      <Select
                        value={vpnType}
                        onChange={(e) => setVpnType(e.target.value)}
                        width="auto"
                        border="none"
                        focusBorderColor="transparent"
                        icon={<DropdownIco />}
                      >
                        <option value="OpenVPN">OpenVPN</option>
                        <option value="WireGuard">WireGuard</option>
                      </Select>
                    </Flex>
                    <Button
                      bg="#EDEBFF"
                      color="#422AFB"
                      _hover={{ bg: "#EDEBFF" }}
                      _active={{ bg: "#d2c7ff" }}
                      borderRadius={12}
                      onClick={handleUserManagementClick}
                    >
                      User Management
                    </Button>
                  </VStack>
                </Box>

                <Box>
                  <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                    Virtual Network Setup Cost
                  </Text>
                  <Flex mb={2} justifyContent="space-between">
                    <Text>Region - </Text>{" "}
                    <Text ml={2}>{data["Setup Region"]}</Text>
                  </Flex>
                  <Flex mb={2} justifyContent="space-between">
                    <Text>Bandwidth - </Text>{" "}
                    <Text ml={2}>{data["Bandwidth"]}</Text>
                  </Flex>
                </Box>
              </VStack>
            </Box>
          </Flex>
          <Button
            variant="solid"
            size="md"
            px="1.2rem"
            width="full"
            py="1.4rem"
            borderRadius={5}
            color="white"
            fontWeight="light"
            bg="#422AFB"
            _hover={{ bg: "#422AFB" }}
            mt={4}
          >
            Update Rules
          </Button>
        </>
      )}
    </Box>
  );
}