import axios from "axios";
import { API_ROUTES } from "constants/api.constants";
import { BASE_API_URL } from "constants/api.constants";
import removeNullValuesAndFormat from "utils/removeNullAndFormat";

export async function createTrialService(trial) {
	try {
		const url = BASE_API_URL + API_ROUTES.CREATE_TRIAL;
		const response = await axios.post(url, trial);
		return response.data;
	} catch (error) {
		return error.response;
	}
}

export async function createEnrollService(enroll) {
	try {
		const url = BASE_API_URL + API_ROUTES.CREATE_ENROLL;
		const response = await axios.post(url, enroll);
		return response.data;
	} catch (error) {
		return error.response;
	}
}

export async function getOrdersService(reqObj) {
	try {
		let url = BASE_API_URL + API_ROUTES.GET_ORDERS;
		let params = removeNullValuesAndFormat(reqObj);
		if (params.length) {
			url += "?" + params.join("&");
		}
		const response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
			},
		});
		return response;
	} catch (error) {
		return error.response;
	}
}


