import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useVerify } from "./useVerify";
import { useEffect } from "react";

export const Login = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const token = queryParams.get("token");
	const email = queryParams.get("email");

	const { verify } = useVerify();

	useEffect(() => {
		verify({ email, token });
	}, [email, token, verify]);

	return (
		<Box position="relative">
			<Center height={"100vh"}>
				<Text fontSize="2xl">Verifying your account...</Text>
				<Spinner
					thickness="4px"
					speed="0.65s"
					emptyColor="gray.200"
					color="blue.500"
					size="xl"
				/>
			</Center>
		</Box>
	);
};
