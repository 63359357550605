import React, { useState } from "react";
import {
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Button,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg';
import { DashBoardTable } from "components/table/Table";
import AddNewVPN from "components/OnboardForm/AddNewVPN";

const heads = [
  "Account Number",
  "Region",
  "VPN Use Allowed",
  "VPN Type",
];

const UserManagement = ({ isCreate, setIsCreate }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleCreate = () => {
    setIsCreate(true);
  };

  return (
    <Box
    >
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        {!isCreate && (
          <>
            <InputGroup width={{ base: "100%", md: "450px" }}>
              <InputLeftElement>
                <Icon as={SearchIcon} />
              </InputLeftElement>
              <Input
                placeholder="Search..."
                value={searchTerm}
                bg="white"
                color="navy.700"
                border="none"
                onChange={handleSearchChange}
                marginRight={4}
              />
            </InputGroup>
            <Button
              onClick={toggleCreate}
              variant="solid"
              size="md"
              px={"1.2rem"}
              py={"1.4rem"}
              borderRadius={5}
              bg="#422AFB"
              color="white"
              fontWeight="light"
              _hover={{ bg: "#422AFB", opacity: 0.8 }}
              _active={{ bg: "#422AFB", opacity: 0.6 }}
            >
              Add New User
            </Button>
          </>
        )}
      </Flex>

      {isCreate ? (
        <AddNewVPN />
      ) : (
        <DashBoardTable heads={heads} data={[]} />
      )}
    </Box>
  );
};

export default UserManagement;