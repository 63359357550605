import {
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Button,
} from "@chakra-ui/react";
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import React, { useState } from "react";
import { DashBoardTable } from "components/table/Table";
import CreateCustomerForm from "components/OnboardForm/CreateCustomerForm";

const heads = [
  "Account Number",
  "Name",
  "Email Address",
  "Industry",
  "Mailing Address",
];

const Customers = ({ isCreate, toggleCreate }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isCustomerCreated, setIsCustomerCreated] = useState(false);

  const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
  };

  const handleCustomerCreationComplete = () => {
      toggleCreate();
      setIsCustomerCreated(true);
  };

  return (
      <>
          <Box
              pt={isCreate? {md:"45px"}:{ base: "135px", md: "100px", xl: "100px" }}
              px={{ base: "15px", md: "15px" }}
          >
              <Flex justifyContent="space-between" mb={4} alignItems="center">
                  {!isCreate && (
                      <InputGroup width={{ base: "100%", md: "450px" }}>
                          <InputLeftElement>
                          <Icon as={SearchIcon} />
                          </InputLeftElement>
                          <Input
                              placeholder="Search..."
                              value={searchTerm}
                              bg="white"
                              color="navy.700"
                              border="none"
                              onChange={handleSearchChange}
                              marginRight={4}
                          />
                      </InputGroup>
                  )}
                  <Flex gap={4} justifyContent="flex-end" flex="1">
                      {!isCreate && (
                          <Button
                              onClick={() => { /* handle edit functionality */ }}
                              variant="solid"
                              size="md"
                              px={"1.4rem"}
                              py={"1.4rem"}
                              borderRadius={5}
                              border="2px solid #DFE5ED"
                              bg="white"
                              color="black"
                              fontWeight="light"
                              _hover={{ opacity: 0.8 }}
                              _active={{ opacity: 0.6 }}
                              leftIcon={<Icon as={EditIcon} />}
                          >
                              Edit
                          </Button>
                      )}

                      <Button
                          onClick={toggleCreate}
                          variant="solid"
                          size="md"
                          px={"1.2rem"}
                          py={"1.4rem"}
                          borderRadius={5}
                          bg={isCreate ? "#FF6060" : "#422AFB"}
                          color="white"
                          fontWeight="light"
                          _hover={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.8 }}
                          _active={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.6 }}
                      >
                          {isCreate ? "Cancel" : "Create New Customer"}
                      </Button>
                  </Flex>
              </Flex>

              {isCreate ? (
                  <CreateCustomerForm
                      setIsCreate={toggleCreate}
                      onComplete={handleCustomerCreationComplete}
                  />
              ) : (
                  <DashBoardTable heads={heads} data={[]} onRowClick={() => {}}/>
              )}
          </Box>
      </>
  );
};

export default Customers;