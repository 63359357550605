import React from "react";
import {
	Link,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Checkbox,
	Stack,
	FormErrorMessage,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import NavigationButtons from "../../NavigationButtons";

const schema = z.object({
	agreement: z.boolean().refine((val) => val === true, {
		message: "You must agree to the terms and conditions",
	}),
	name: z.string().min(1, { message: "Name is required" }),
});

const CustomerAgreementForm = ({
	setStep,
	formData,
	updateFormData,
	isReadOnly = false,
	step,
	finalStep,
}) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
		defaultValues: {
			agreement: false,
			name: "",
		},
	});

	const onSubmit = (data) => {
		const customerObject = {
			signed_name: data.name,
		};

		// Update the form data state with customer agreement
		updateFormData({
			customer_agreement: customerObject,
		});

		// Proceed to the next step
		setStep((prev) => prev + 1);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={8}>
				<Heading as="h2" size="lg" mb={6} fontWeight={600}>
					Customer Agreement
				</Heading>

				<FormControl isDisabled={isReadOnly} isInvalid={!!errors.agreement}>
					<Controller
						name="agreement"
						control={control}
						render={({ field }) => (
							<Checkbox {...field}>
								I have read and agree to the Titan Cloud Storage{" "}
								<Link
									_hover={{ textDecorationLine: "underline" }}
									href="https://www.titancloudstorage.com/customer-agreement/"
									target="_blank"
									color="blue.500"
									rel="noopener noreferrer"
								>
									Customer Agreement
								</Link>
							</Checkbox>
						)}
					/>
					<FormErrorMessage>{errors.agreement?.message}</FormErrorMessage>
				</FormControl>

				<FormControl isDisabled={isReadOnly} isInvalid={!!errors.name}>
					<FormLabel>Signed Name</FormLabel>
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<Input placeholder="Signed Name" {...field} />
						)}
					/>
					<FormErrorMessage>{errors.name?.message}</FormErrorMessage>
				</FormControl>

				<NavigationButtons
					step={step}
					setStep={setStep}
					finalStep={finalStep}
					onSubmit={handleSubmit(onSubmit)}
				/>
			</Stack>
		</form>
	);
};

export default CustomerAgreementForm;
