import React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  HStack,
  Flex,
  Text,
  Collapse,
  useDisclosure,
  Button,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { ReactComponent as ComputeIcon } from "../../../assets/icons/compute.svg";
import { ReactComponent as UpArrowIcon } from "../../../assets/icons/uparrow.svg";
import Links from "components/sidebar/components/Links";

const ComputeMenu = ({ routes }) => {
  const { isOpen, onToggle } = useDisclosure();
  const activeColor = useColorModeValue("gray.700", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const activeIcon = useColorModeValue("brand.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const activeBgColor = useColorModeValue("blue.50", "blue.900");

  return (
    <Box>
      {" "}
      <Box
        bg={"transparent"}
        _focus={{ bg: brandColor }}
        borderRadius="lg"
        ml="0"
        mr={3}
      >
        <Button
          onClick={onToggle}
          rightIcon={
            <Icon
              as={UpArrowIcon}
              transform={isOpen ? "rotate(0deg)" : "rotate(180deg)"}
              transition="transform 0.2s"
            />
          }
          w="100%"
          fontWeight="medium"
          justifyContent="space-between"
          bg={"transparent"}
          _focus={{ bg: activeBgColor, boxShadow: "none" }}
          _hover={{ textDecoration: "none" }}
          px="10px"
          py="5px"
          borderRadius="md"
        >
          <Flex alignItems="center">
            <Box color={isOpen ? activeIcon : textColor} me="18px">
              <ComputeIcon />
            </Box>
            <Text
              color={isOpen ? activeColor : textColor}
              fontWeight={isOpen ? "bold" : "normal"}
            >
              Compute
            </Text>
          </Flex>
        </Button>
      </Box>
      <Collapse in={isOpen} animateOpacity style={{ paddingLeft: "1rem" }}>
        <Links routes={routes} />
      </Collapse>
    </Box>
  );
};

export default ComputeMenu;
