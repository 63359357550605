import React from "react";
import {
  Box,
  Text,
  Select,
  Input,
  Link,
  Icon,
} from "@chakra-ui/react";
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/dropdown.svg';

const AccountProductInfo = ({ handleBackClick, handleNextClick }) => {
  return (
    <Box width="100%">
      <Box mb={6}>
        <Text fontWeight="bold" fontSize="md" mb={2}>
          Account Information
        </Text>
        <Select placeholder="Select Distributor" mb={4} bg="white" icon={<Icon as={DropdownIcon} />}/>
        <Input placeholder="Account Number" mb={2} bg="white" />
        <Text textAlign="right" mb={6}>
          Don’t have the customer yet?{" "}
          <Link href="#" textDecoration="underline" _hover={{textDecoration:"underline"}}>
            Create one
          </Link>
        </Text>

        <Text fontWeight="bold" fontSize="md" mb={2}>
          Product Information
        </Text>
        <Select defaultValue="ACS" isReadOnly mb={4} bg="white" icon={<Icon as={DropdownIcon} />}>
          <option value="ACS">Product Selection: ACS</option>
        </Select>
        <Select placeholder="Select Region" mb={6} bg="white" icon={<Icon as={DropdownIcon} />}/>
      </Box>
      
    </Box>
  );
};

export default AccountProductInfo;
