import React, { useState, useEffect } from "react";
import { Box, Flex, InputGroup, InputLeftElement, Input, Icon, Button } from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { DashBoardTable } from "components/table/Table";
import { useGetFirewalls } from "features/Vm/useGetFirewalls";

const heads = ["Account Number", "Allowed Ports", "Blocked Ports", "Rules"];

const Firewall = ({ isCreate, toggleCreate }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({ account_number: "" });

  const { isLoading, firewall, refetch } = useGetFirewalls(filters);
    
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setFilters({ account_number: value });
  };

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const formatFirewallData = (firewall) => {
    if (!firewall) return {};
    
    const allowedPorts = firewall.firewallRules
      .filter((rule) => rule.action === "Allow")
      .map((rule) => rule.destinationPort)
      .join(", ");
    const blockedPorts = firewall.firewallRules
      .filter((rule) => rule.action === "Deny")
      .map((rule) => rule.destinationPort)
      .join(", ");
    const rules = firewall.firewallRules
      .map((rule) => `${rule.ruleName} (${rule.action})`)
      .join(", ");

    return {
      accountNumber: firewall.accountNumber,
      allowedPorts,
      blockedPorts,
      rules,
    };
  };

  const formattedData = firewall ? [formatFirewallData(firewall)] : [];

  return (
    <Box
      pt={
        isCreate
          ? { md: "45px" }
          : { base: "135px", md: "100px", xl: "100px" }
      }
      px={{ base: "15px", md: "15px" }}
    >
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        {!isCreate && (
          <InputGroup width={{ base: "100%", md: "450px" }}>
            <InputLeftElement>
              <Icon as={SearchIcon} />
            </InputLeftElement>
            <Input
              placeholder="Search Account Number..."
              value={searchTerm}
              bg="white"
              color="navy.700"
              border="none"
              onChange={handleSearchChange}
              marginRight={4}
            />
          </InputGroup>
        )}
        <Flex gap={4} justifyContent="flex-end" flex="1">
          <Button
            onClick={toggleCreate}
            variant="solid"
            size="md"
            px={"1.2rem"}
            py={"1.4rem"}
            borderRadius={5}
            bg={isCreate ? "#FF6060" : "#422AFB"}
            color="white"
            fontWeight="light"
            _hover={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.8 }}
            _active={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.6 }}
          >
            {isCreate ? "Cancel" : "Create New Firewall Rule"}
          </Button>
        </Flex>
      </Flex>
      <DashBoardTable heads={heads} data={formattedData} isLoading={isLoading} onRowClick={() => {}}/>
    </Box>
  );
};

export default Firewall;