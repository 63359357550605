import React, { useEffect } from "react";
import {
	FormControl,
	Input,
	FormErrorMessage,
	Heading,
	Stack,
	Grid,
	Box,
	RadioGroup,
	Radio,
} from "@chakra-ui/react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import NavigationButtons from "../../NavigationButtons";
import LocationSelector from "../../LocationSelector";

const formSchema = z.object({
	whoIsBillingFor: z
		.string()
		.nonempty({ message: "Please select a billing option" }),
	firstName: z.string().nonempty({ message: "First name is required" }),
	lastName: z.string().nonempty({ message: "Last name is required" }),
	email: z
		.string()
		.nonempty({ message: "Email is required" })
		.email({ message: "Invalid email" }),
	addressLine1: z.string().nonempty({ message: "Address line 1 is required" }),
	addressLine2: z.string().optional(),
	city: z.string().nonempty({ message: "City is required" }),
	state: z.string().nonempty({ message: "State is required" }),
	country: z.string().nonempty({ message: "Country is required" }),
	zipCode: z
		.string({
			required_error: "Zip code is required",
		})
		.nonempty({ message: "Zip code is required" }),
});

const BillingInformationForm = ({
	setStep,
	formData,
	updateFormData,
	isReadOnly = false,
	step,
	finalStep,
}) => {
	const methods = useForm({
		resolver: zodResolver(formSchema),
		defaultValues: {
			whoIsBillingFor:
				formData.billing_information?.whoIsBillingFor || "partner",
			firstName: formData.billing_information?.basic_details?.first_name || "",
			lastName: formData.billing_information?.basic_details?.last_name || "",
			email: formData.billing_information?.basic_details?.email || "",
			addressLine1:
				formData.billing_information?.mailing_address?.address_line_1 || "",
			addressLine2:
				formData.billing_information?.mailing_address?.address_line_2 || "",
			city: formData.billing_information?.mailing_address?.city || "",
			state: formData.billing_information?.mailing_address?.state || "",
			country: formData.billing_information?.mailing_address?.country || "",
			zipCode:
				formData?.billing_information?.mailing_address?.zip_code.toString() ||
				"",
		},
	});

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = methods;

	// Watch the billing type
	const billingType = watch("whoIsBillingFor");

	// Set the default email based on the billing type
	useEffect(() => {
		let defaultEmail = "";
		if (billingType === "partner") {
			defaultEmail = formData?.account_information?.basic_details?.email || "";
		} else if (billingType === "customer") {
			defaultEmail =
				formData?.product_configuration?.configuration?.customer_email || "";
		}
		setValue("email", defaultEmail);
	}, [billingType, setValue]);

	const onSubmit = (data) => {
		const isCustomerBilling = data.whoIsBillingFor === "customer";

		const info = {
			whoIsBillingFor: data.whoIsBillingFor,
			isThisACustomerBilling: isCustomerBilling,
			basic_details: {
				email: data.email,
				first_name: data.firstName,
				last_name: data.lastName,
			},
			mailing_address: {
				address_line_1: data.addressLine1,
				address_line_2: data.addressLine2,
				city: data.city,
				state: data.state,
				country: data.country,
				zip_code: data.zipCode,
			},
		};

		updateFormData({
			billing_information: info,
		});
		setStep((prev) => prev + 1);
	};

	return (
		<FormProvider {...methods}>
			<form>
				<Stack spacing={8}>
					<Heading as="h1" size="lg" fontWeight="600">
						Billing Information
					</Heading>
					<Box>
						<Heading as="h2" fontWeight="600" size="md" fontSize="14px" mb={2}>
							Who is going to be billed?
						</Heading>
						<FormControl isInvalid={!!errors.whoIsBillingFor}>
							<RadioGroup
								defaultValue={billingType}
								onChange={(val) => setValue("whoIsBillingFor", val)}
							>
								<Stack direction="row" spacing="2rem">
									<Radio value="partner">Partner Billing</Radio>
									<Radio value="customer">Customer Billing</Radio>
								</Stack>
							</RadioGroup>
							<FormErrorMessage>
								{errors.whoIsBillingFor && errors.whoIsBillingFor.message}
							</FormErrorMessage>
						</FormControl>
					</Box>
					<Box>
						<Heading as="h2" fontWeight="600" size="md" fontSize="14px" mb={2}>
							Basic Details
						</Heading>
						<Grid templateColumns="repeat(2, 1fr)" gap={4} fontSize="12px">
							<FormControl
								isDisabled={isReadOnly}
								isInvalid={!!errors.firstName}
							>
								<Input placeholder="First Name" {...register("firstName")} />
								<FormErrorMessage>
									{errors.firstName && errors.firstName.message}
								</FormErrorMessage>
							</FormControl>

							<FormControl
								isDisabled={isReadOnly}
								isInvalid={!!errors.lastName}
							>
								<Input placeholder="Last Name" {...register("lastName")} />
								<FormErrorMessage>
									{errors.lastName && errors.lastName.message}
								</FormErrorMessage>
							</FormControl>

							<FormControl
								isDisabled={isReadOnly}
								isInvalid={!!errors.email}
								gridColumn="span 2"
							>
								<Input
									type="email"
									placeholder="Email"
									{...register("email")}
									value={watch("email")}
								/>
								<FormErrorMessage>
									{errors.email && errors.email.message}
								</FormErrorMessage>
							</FormControl>
						</Grid>
					</Box>

					<Box>
						<Heading as="h2" fontWeight="600" size="md" fontSize="14px" mb="2">
							Billing Address
						</Heading>
						<Grid templateColumns="repeat(2, 1fr)" gap={4}>
							<FormControl
								isDisabled={isReadOnly}
								isInvalid={!!errors.addressLine1}
							>
								<Input
									placeholder="Address Line 1"
									{...register("addressLine1")}
								/>
								<FormErrorMessage>
									{errors.addressLine1 && errors.addressLine1.message}
								</FormErrorMessage>
							</FormControl>

							<FormControl isDisabled={isReadOnly}>
								<Input
									placeholder="Address Line 2"
									{...register("addressLine2")}
								/>
							</FormControl>
							<LocationSelector
								isReadOnly={isReadOnly}
								defaultCountry={
									formData.billing_information?.mailing_address?.country || ""
								}
								defaultState={
									formData.billing_information?.mailing_address?.state || ""
								}
								defaultCity={
									formData.billing_information?.mailing_address?.city || ""
								}
							/>

							<FormControl isDisabled={isReadOnly} isInvalid={!!errors.zipCode}>
								<Input
									type="text"
									placeholder="Zip Code"
									{...register("zipCode")}
								/>
								<FormErrorMessage>
									{errors.zipCode && errors.zipCode.message}
								</FormErrorMessage>
							</FormControl>
						</Grid>
					</Box>

					<NavigationButtons
						step={step}
						setStep={setStep}
						finalStep={finalStep}
						onSubmit={handleSubmit(onSubmit)}
					/>
				</Stack>
			</form>
		</FormProvider>
	);
};

export default BillingInformationForm;
