import axios from "axios";
import { API_ROUTES } from "constants/api.constants";
import { BASE_API_URL } from "constants/api.constants";

export async function createOrderService(order) {
	try {
		const url = BASE_API_URL + API_ROUTES.CREATE_ORDER;
		const response = await axios.post(url, order);
		return response;
	} catch (error) {
		return error.response;
	}
}

export async function createEnrollmentService(enrollment, email, token) {
	try {
		const url = `${
			BASE_API_URL + API_ROUTES.CREATE_ENROLLMENT
		}?email=${email}&token=${token}`;
		const response = await axios.post(url, enrollment);
		return response;
	} catch (error) {
		return error.response;
	}
}

export async function getEnrollmentService(email, token) {
	try {
		const url = `${
			BASE_API_URL + API_ROUTES.GET_ENROLLMENT
		}?email=${email}&token=${token}`;
		const response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
			},
		});
		return response;
	} catch (error) {
		return error.response;
	}
}
